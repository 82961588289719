import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { MainService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor( private _http: HttpClient, private mainsService: MainService) {
  }

  createIssue (title, description): Observable<any> {

    return this._http.post('/api/v2/general/createIssue/', 
        JSON.stringify({ 
            squad: this.mainsService.userToken.area, 
            user: this.mainsService.userToken.username, 
            source: 'ESP-Frontend', 
            title, 
            description}), this.mainsService.httpOptions)
        .pipe(
            map(res => {
                return res;
        }),
            catchError(this.mainsService.handleError)
        );
}
}
