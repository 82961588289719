import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Issue } from '../models/issue.model';

@Injectable({
  providedIn: 'root'
})
export class IssueService {

  private readonly API = environment.API;

  constructor(private http: HttpClient) { }

  /* Métodos para criação de issues */
  createIssue(issue: Issue) {
    return this.http.get<any>(`${this.API}create-issue`, {
      params: {
        '_id': String(issue._id),
        'squad': String(issue.squad),
        'source': String(issue.source),
        'function': String(issue.func),
        'line': String(issue.line),
        'query': String(issue.query),
        'modulo': String(issue.modulo),
        'planning': String(issue.planning),
        'area': String(issue.area),
        'user': String(issue.user),
        '_smarttest': String(issue._smarttest),
        // 'advplerrors': String(issue.advplerrors),
        // 'dbmserrors': String(issue.dbmserrors)
      }
    })
      .pipe(
        //delay(100),
        // tap(console.log)
      );
  }

  checkIssue(issuecode: String, queryid: String) {
    return this.http.get<any>(`${this.API}check-issue`, {
      params: {
        'id': String(queryid),
        'issuecode': String(issuecode),
      }
    })
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

}