export class JamesB {
    public username: string;
    public remote: string;
    public server: string;
    public reason: string;
    public area: string;
    public expireDate: string;
    public isAreaBound: boolean;
    public approved: boolean;
    
}