
<div #scrollTarget class="po-lg-12 po-md-12 po-sm-12">
    <po-widget #scrollTarget  p-height="350" 
        p-no-shadow="false" 
        p-title="Consulte e tire suas dúvidas com o nosso chat! ">
            <section #scrollTarget class="chat container" id="chat">
            <p class="chat__bolha chat__bolha--bot">
                Olá! <br>
                Eu sou o assistente virtual da EngproDados e estou aqui para oferecer suporte em 
                diversas áreas, incluindo Análise de Queries, Banco de Dados, Infraestrutura. <br>
                É importante salientar que as informações que forneço não substituem a análise 
                de um especialista. <br>
                Recomendo que teste e valide todas as informações que compartilho, 
                já que estou constantemente aprendendo e me aprimorando para oferecer assistência 
                cada vez melhor. <br>
                Como posso ajudá-lo hoje?
            </p>
            <p *ngFor="let message of messages let i = index" [id]="'message-' + i" [class]="message.class">{{message.content}}</p>
            </section>
    </po-widget>
    <section class="entrada container">
      <div class="entrada__container">
        <input type="text" class="entrada__input" [(ngModel)]="pergunta" placeholder="Enviar uma mensagem" id="input" (keydown.enter)="enviarPergunta()">
        <button class="button-box" aria-label="Botão de enviar" id="botao-enviar" (click)="enviarPergunta()">
          <i class="icone icone--enviar-mensagem"></i>
        </button>
      </div>
    </section>

  </div>