<head>
  <!-- Matomo Tag Manager -->
  <script>
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='http://10.171.80.34:9603/js/container_akjXKwvF.js'; s.parentNode.insertBefore(g,s);
    </script>
    <!-- End Matomo Tag Manager -->
  </head>
<po-page-default p-title="Databases">

    <div class="po-row">
  
      <po-button
        class="po-md-2"
        (p-click)="criadb.open()"
        p-kind="primary"
        p-icon="po-icon-database"
        p-label="Criar Database" >
      </po-button>
  
      <po-button
        class="po-md-2"
        (p-click)="getDatabases(); getFiles(); resetFilters();"
        p-label="Atualizar" 
        p-icon="po-icon-refresh">
      </po-button>
  
      <po-button
        class="po-md-2"
        (p-click)="resetValuesUpload(); uploadModal.open()"
        p-label="Meus Backups" 
        p-icon="po-icon-upload">
      </po-button>
  
      <po-button
        class="po-md-2"
        (p-click)="toggleFilters();"
        p-label="Filtros" 
        p-icon="po-icon-filter">
      </po-button>
  
    </div>
    
    <div class="container po-row filterInstancesRow">
      <br>
        <div class="button-container" hidden>
          <po-select
            class="filterInstancesSelect po-md-lg-sm-4"
            name="filterOptionsSelect"
            [(ngModel)]="filterSelected"
            (p-change)="onChangeFilterSelect($event)"
            p-label="Filtro"
            [p-options]="filterOptionsSelect"
            hidden
            >
          </po-select>  
            
          <po-button
            class="filterInstancesInvertButton"
            name="filterInstancesInvertButton"
            (p-click)="invertFilterOrder()"
            [p-icon]="templateButton"
            p-kind="tertiary"
            style="display: none;"
          >
          </po-button> 

          <ng-template #templateButton>
            <span class="po-icon po-icon-sort" style="font-size: 30px;" ></span>
          </ng-template>

          <po-checkbox-group class="filterInstancesChkGroup p-pl-5"
            name="filterInstancesChkGroup"
            p-label="Status"
            (p-change)="filterInstances(filterInstancesModel)"
            [(ngModel)]="filterInstancesModel"
            [p-indeterminate]="true"
            [p-columns]="3"
            [p-options]="filterOptions"
            hidden
            >
          </po-checkbox-group>  
        </div> 
    </div>
  
    <po-divider></po-divider>
    <po-list-view
      p-hide-select-all
      [p-actions]="eventsObject"
      [p-items]="databasesView"
      p-property-title="name">
  
      <ng-template p-list-view-content-template let-item >
      <div *ngIf="item.kind !== 'database'">
        <div class="po-row">
            <po-info p-label="ID" class="po-md-3" [p-value]="item.id"></po-info>

            <po-tag p-label="Status" class="po-md-2" [p-orientation]="vertical" [p-type]="item.statusType" [p-value]="item.status"></po-tag>
    
            <po-info p-label="Expiração (dias)" class="po-md-2" [p-value]="item.remainingTime" ></po-info>
  
            <po-info p-label="Software" class="po-md-2" [p-value]="item.softwareType"></po-info>
        </div>
        <br>
        <div class="po-row">
            <po-info p-label="Username" class="po-md-3" [p-value]="item.username" ></po-info>
  
            <po-info p-label="DB IP" class="po-md-2" [p-value]="item.dbipporta" ></po-info>
            
            <po-info p-label="DBAccess IP" class="po-md-2" [p-value]="item.dbaccess" ></po-info>
            
            <po-info p-label="DB Alias" class="po-md-2" [p-value]="item.alias" ></po-info>

            <po-info p-label="DBAPI:" class="po-md-2" p-value="Baixar versão atual" p-url="https://arte.engpro.totvs.com.br/engenharia/devops/artefatos/dbapi-esp-dbaccess/dbapi-esp-dbaccess.zip" ></po-info>
        </div>
        <br>
        <div class="po-row">
          <po-info p-label="Proprietário: " class="po-md-2" [p-value]="item.owner" ></po-info>
          <po-info p-label="Usuários compartilhados" class="po-md-7" [p-value]="item.shared" ></po-info>
          <po-info *ngIf="item.active === true" p-label="Criado em: " class="po-md-3" [p-value]="item.createdAt" ></po-info>
          <po-info *ngIf="item.active === false" p-label="Deletado em: " class="po-md-3" [p-value]="item.updatedAt" ></po-info>
        </div>
      </div>


      <div *ngIf="item.kind === 'database'">
        <div class="po-row">
          <po-info p-label="ID" class="po-md-3" [p-value]="item.id"></po-info>
          <po-tag p-label="Status" class="po-md-3" [p-orientation]="vertical" [p-type]="item.statusType" [p-value]="item.status"></po-tag>
          <po-info p-label="Expiração (dias)" class="po-md-3" [p-value]="item.remainingTime" ></po-info>
          <po-info p-label="Software" class="po-md-3" [p-value]="item.softwareType"></po-info>
        </div>
        <br />
        <div class="po-row">
          <po-info p-label="Database IP" class="po-md-3" [p-value]="item.dbipporta" ></po-info>
          <po-info p-label="Username" class="po-md-3" [p-value]="item.username" ></po-info>
          <po-info p-label="Password" class="po-md-3 hiddenPassword" [p-value]="item.visualPassword" (click)="displayPassword(item)" title="click to show password"></po-info>
        </div>
        <br />
        <div class="po-row">
          <po-info p-label="DBAccess IP" class="po-md-3" [p-value]="item.dbaccess" ></po-info>
          <po-info p-label="Alias" class="po-md-3" [p-value]="item.alias" ></po-info>
          <po-info p-label="DBApi:" class="po-md-3" p-value="Baixar versão atual" p-url="https://arte.engpro.totvs.com.br/engenharia/devops/artefatos/dbapi-esp-dbaccess/dbapi-esp-dbaccess.zip" ></po-info>
        </div>
        <br />
        <div class="po-row">
          <po-info p-label="Proprietário: " class="po-md-3" [p-value]="item.owner" ></po-info>
          <po-info p-label="Usuários compartilhados" class="po-md-3" [p-value]="item.shared" ></po-info>
          <po-info *ngIf="item.active === true" p-label="Criado em: " class="po-md-3" [p-value]="item.createdAt" ></po-info>
          <po-info *ngIf="item.active === false" p-label="Deletado em: " class="po-md-3" [p-value]="item.updatedAt" ></po-info>
        </div>
      </div>

  
      </ng-template>
    </po-list-view>  
    <notificationComponentHM></notificationComponentHM>
    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>
  </po-page-default>

<po-modal #criadb
  p-title="Novo Database"
  [p-primary-action]="dbCreate"
  [p-secondary-action]="cancelCreate"
  >    

  <p class="infoLabel" style="font-weight: bold;">ATENÇÃO!</p>
  <p class="infoLabel">Todas as novas instancias são criadas com apenas 7 dias de duração, podendo ser prorrogadas como antes.</p>
  
  <div class="po-row">

    <po-input
      class="po-md-9"
      name="Nome"
      [(ngModel)]="_name"
      p-label="Nome">
    </po-input>

    <po-select
      class="po-lg-md-sm-3 "
      name="softwares"
      [(ngModel)]="softwareSelected"
      p-label="DB Software"
      (p-change)="onChangeSoftware($event)"
      [p-required]="true"
      [p-options]="software">
    </po-select>

  </div>

  <div class="po-row">
    <po-select
      class="po-lg-md-sm-6 receitasSelect"
      name="receitas"
      [(ngModel)]="receitaID"
      (p-change)="onChangeRecipe($event)"
      p-label="Receitas"
      [p-required]="true"
      [p-disabled]="disableRecipe"
      [p-options]="receitasFiltered">
    </po-select>

    <po-select
      class="po-lg-md-sm-6"
      name="backups"
      [(ngModel)]="chosenBackup"
      p-label="Meus Backups"
      [p-required]="true"
      [p-disabled]="disableRestore"
      [p-options]="backups">
    </po-select>
    
  </div>
  
  <div class="po-row">
    <po-password
    class="po-sm-12"
    name="password"
    [p-disabled]="!showPassword"
    [(ngModel)]="_password"
    (p-change-model)="checkPassword($event)"
    p-clean
    p-help="Guarde essa senha! Ela será utilizada para acesso ao banco e não será armazenada no ESP (min. 5 caracteres, sem * / - _ . ,)"
    p-label="Senha"
    p-minlength="5"
    p-pattern="[a-zA-Z0-9@%?.,]"
    p-error-pattern="Padrão de senha invalido. Os simbolos permitidos são @ % ?"
    p-required>
  </po-password>
  </div>

  

  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>
</po-modal>

<po-modal #deletDatabase
    p-title="Excluir Instância"
    [p-primary-action]="okDelete"
    [p-secondary-action]="cancelDelete">

    <div class="po-row">

      <po-select
        class="po-lg-6"
        name="delete"
        p-label="Deseja excluir a instância?"
        [p-required]="true"
        [(ngModel)]="canDelete"
        [p-options]="[{label: 'Não', value: '1'}, {label: 'Sim', value: '2'}]">
      </po-select>

    </div>

</po-modal>

<po-modal #renameDatabase
    p-title="Prorrogar/Renomear Database"
    [p-primary-action]="prorrogaInst">

    <div class="po-row">

      <div class="po-row">
        <po-input
          class="po-md-12"
          name="Nome"
          [(ngModel)]="_name"
          p-label="Nome">
        </po-input>
      </div>

      <po-radio-group
        class="po-lg-8"
        p-columns="3"
        name="Expiração"
        p-label="Expiração"
        [p-options]="expiraOptions"
        [(ngModel)]="expiraNew">
      </po-radio-group>
    </div>

</po-modal>

<po-modal #backupRestore
  p-title="Restore do Database"
  [p-primary-action]="okRestore"
  [p-secondary-action]="cancelRestore">

  <div class="po-row">
    <po-select
      class="po-lg-6"
      name="backups"
      [(ngModel)]="chosenBackup"
      p-label="Backups disponíveis"
      [p-required]="true"
      [p-options]="backups">
    </po-select>  
  </div>

</po-modal>

<po-modal #historyModal 
  [p-title]="titleDetailsModal"
  [p-primary-action]="okHistory">
  <po-table
    [p-columns]="columnsDetails"
    [p-items]="history">
  </po-table>
</po-modal>

<po-modal #shareModal
    p-title="Compartilhar Database"
    [p-primary-action]="okShare"
    [p-secondary-action]="cancelShare">

    <div class="po-row">
      <po-info p-label="Usuários compartilhados" class="po-md-12" [p-value]="usersSelected" ></po-info>
    </div>

    <div class="po-row">
      <po-multiselect
        name="usersSelected"
        class="po-md-12"
        [(ngModel)]="usersSelected"
        label="Usuários"
        [p-options]="usersInArea"
        p-required>
      </po-multiselect>
    </div>

</po-modal>

<po-modal #changePassword
  p-title="Trocar Senha do Database"
  [p-primary-action]="okChangePassword"
  [p-secondary-action]="cancelChangePassword">

  <div class="po-row">
    <po-info p-label="Insira a nova senha para a database:" class="po-md-6"></po-info>     
  </div>
  <div class="po-row">
    <po-password
      class="po-md-12"
      name="Senha"
      [(ngModel)]="passwordNew"
      p-label="Senha">
    </po-password>
  </div>
</po-modal>

<po-modal #uploadModal 
    p-title="Meus Backups"
    [p-primary-action]="sendUpload"
    [p-secondary-action]="cancelUpload">

    <po-table
      [p-columns]="uploadColumns"
      [p-items]="uploadItems"
      [p-actions]="uploadActions">
    </po-table>

    <po-divider></po-divider>

    <div class="sample-container" *ngIf="canUpload">
      <po-upload #upload
        class="po-md-12"
        name="bakcup"
        [(ngModel)]="selectedFile"
        p-label="Selecione o arquivo com a imagem do banco:"
        p-hide-send-button
        p-url="https://api.esp.engpro.totvs.com.br/api/v1/files"
        (p-error)="resumeUploadError()"
        (p-success)="resumeUploadSuccess()">
      </po-upload>
    </div>

    <div class="sample-container" *ngIf="!canUpload">
      <po-info
        p-label="Limite máximo atingido!"
        p-value="Você atingiu o limite de 4 arquivos. Para novos uploads você deve apagar arquivos já existentes." >
      </po-info>
    </div>
    
    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>
  
</po-modal>
