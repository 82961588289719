export class Localizacoes {
    public id: string;
    public release: string;
    public localizacao: string;
    public regional_language: string;
    public link_menu: string;
    public link_help: string;
    public link_dic: string;
    public congelada: string;
    public published: boolean;
}
