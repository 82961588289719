import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabaseComponent } from './database.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { DatabaseService } from './database.service';
import { MainService } from 'src/app/app.service';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

@NgModule({
  declarations: [
    DatabaseComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    PoModule,
    PoTemplatesModule,
    NotificationItemComponent
  ],
  providers: [
    MainService,
    DatabaseService
  ]
})
export class DatabaseModule { }
