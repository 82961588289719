export class ProtheusINI {
    public instance: string;
    public type: string;
    public ini: any[];
}

export class IniParameter {
    public group: string;
    public values: Object[];
    public protected: boolean;
}