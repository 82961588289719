<po-page-default p-title="QUERY RUNTIME TEST">
    <div class="po-row">
      <div class="po-md-12">
        <po-tabs>
            <po-tab p-label="Editor" p-active="True">
                <test-query></test-query>
            </po-tab>
            <po-tab p-label="Chat (Beta)">
                <app-chatbot-ai></app-chatbot-ai>
            </po-tab>
        </po-tabs>
      
      </div>
    </div>
</po-page-default>
