import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { AppRoutingModule } from '../app-routing.module';
import { PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { ProtheusAdminComponent } from './admin/protheus-admin/protheus-admin.component';
import { JamesAdminComponent } from './admin/james-admin/james-admin.component';
import { SupportComponent } from './support/support.component';
import { TotvsNewsPreviewComponent } from './totvs-news-preview/totvs-news-preview.component';
import { DatabaseModule } from './database/database.module';
import { ProtheusModule } from './protheus/protheus.module';
import { QueryManagerComponent } from './query-manager/query-manager.component';
import { QueryAnalyzerModule } from './query-analyzer/query-analyzer.module';
import { QueryManagerModule } from './query-manager/query-manager.module';
@NgModule({
  declarations: [
    MenuComponent,
    HomeComponent,
    AdminPanelComponent,
    ProtheusAdminComponent,
    JamesAdminComponent,
    SupportComponent,
    TotvsNewsPreviewComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    PoModule,
    DatabaseModule,
    ProtheusModule,
    PoTemplatesModule,
    QueryAnalyzerModule,
    QueryManagerModule
  ]
})
export class MenuModule { }
