    <div class="po-lg-12 po-md-12">
        <po-widget 
          p-height="400" 
          p-no-shadow="false" 
          (p-setting)="modal.open()" 
          p-title="Insira a sua query e clique em Testar"
          >
    
          <po-code-editor 
            [(ngModel)]=testQuery 
            p-height="250" 
            p-language="sql" 
            p-readonly="false" 
            p-show-diff="false" 
            p-theme="vs-dark"
          ></po-code-editor>
    
          <div class="po-md-12">
            <po-button class="btn-right" [p-label]="statusTest" [p-kind]="btnTypeTest" [p-loading]="btnLoading"
              (p-click)="btnTest()"> </po-button>
          </div>
        </po-widget>
      <br>
    </div>
    <notificationComponentHM></notificationComponentHM>

    <po-modal #modal p-title="Configurations">
        <po-multiselect [(ngModel)]="modelTypeExec" name="optTypeExec" p-label="Tests scope:" [p-options]="optTypeExec"></po-multiselect>
        <po-multiselect [(ngModel)]="modelDatabase" name="optDatabase" p-label="Run DBMS test using:" [p-options]="optDatabase"></po-multiselect>
    </po-modal>

    <po-modal #aiModal
      p-click-out="false"
      p-hide-close="false"
      [p-primary-action]="confirmAIanalisys"
      [p-secondary-action]="refuseAIanalisys"
      p-size="auto"
      p-title="Smart Analyzer (Beta)"
      p-icon="po-icon-document-filled"
    >
      <po-info p-label="Deseja que a query seja analisada e melhorada com IA?" 
      p-value="A query será analisada e o resultado será registrado em uma issue de apoio no Jira."> </po-info>
    </po-modal>

<div *ngIf="dbresult" class="div-list po-sm-12 po-md-12 po-lg-12">
  <po-divider p-label="DBMS Parser & Runtime" ></po-divider>
  <po-table [p-columns]="columnsDB" [p-items]="dbresult" p-sort="true" [p-striped]="true">
      <ng-template p-table-row-template let-rowItem let-i="rowIndex" [p-table-row-template-show]="isApprovedSGBD">
        <po-widget *ngIf="rowItem.plan.length > 1" p-title="Execution plan">
          
          <div class="po-row" *ngIf="rowItem.sgbd != 'MSSQL' ">
            <po-code-editor [(ngModel)]=rowItem.plan
              p-height="300" 
              p-language="sql" 
              p-readonly="true" 
              p-show-diff="false" 
              p-theme="vs-dark">
            </po-code-editor>
          </div>

          <div class="po-row" *ngIf="rowItem.sgbd == 'MSSQL' ">
            <po-code-editor [(ngModel)]=rowItem.plan
              p-height="300" 
              p-language="json" 
              p-readonly="true" 
              p-show-diff="false" 
              p-theme="vs-dark">
            </po-code-editor>
          </div>
        </po-widget>
      </ng-template>
    </po-table>
    <br>
</div>

  <!--RESULTADO DO BANCO DE DADOS-->
<div *ngIf="needAIAnalyzer" class="div-list po-sm-12 po-md-12 po-lg-12">
    <po-divider p-label="Smart Analyzer" ></po-divider>
    <div class="div-card po-sm-12 po-md-12 po-lg-12">
      <br>
      <po-divider p-label="Issue details" class="po-sm-12 po-md-12 po-lg-12"></po-divider>
      <div class="po-sm-12 po-md-4 po-lg-4">
        <p>Issue: <a [href]="issueUrl" target="_blank">{{ this.issueCode }}</a> </p>
      </div>
      <div class="po-sm-12 po-md-4 po-lg-4">
        <p>Status: {{ this.issueStatus }} </p><br>
      </div>
      <br>
    </div>
    <div class="po-row">
      <po-code-editor *ngIf="aiAnalyzer[0]" [(ngModel)]=aiAnalyzer[0][0]
        p-height="300" 
        p-language="sql" 
        p-readonly="true" 
        p-show-diff="false" 
        p-theme="vs-dark">
      </po-code-editor>
    </div>
      <br>
  </div>

<!--RESULTADO DO ADVPL: Por hora estará invisível [hidden]="true"-->
<div [hidden]="true" *ngIf="advpl" class="div-list po-sm-12 po-md-12 po-lg-12">
  <po-divider p-label="ADVPL Parser" ></po-divider>
  <po-table [p-columns]="columnsAdvpl" [p-items]="advpl" p-sort="true" [p-striped]="true">
    <ng-template p-table-row-template let-rowItem let-i="rowIndex" [p-table-row-template-show]="isApprovedAdvpl">
      <po-widget>
          <div class="po-row">

            <span>CHANGEQUERY</span>
            <po-code-editor [(ngModel)]=rowItem.CRETCQ 
              p-height="300" 
              p-language="sql" 
              p-readonly="true" 
              p-show-diff="false" 
              p-theme="vs-dark">
            </po-code-editor>
              
            <span>MSPARSE</span>
            <po-code-editor [(ngModel)]=rowItem.CRETMSP 
              p-height="300"
              p-language="sql" 
              p-readonly="true" 
              p-show-diff="false" 
              p-theme="vs-dark">
            </po-code-editor>

          </div>
        </po-widget>
      </ng-template>
    </po-table>
</div>
<!--FIM DOS RESULTADOS-->


    


