import { NgZone, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { PoChartOptions, PoChartSerie, PoChartType, PoListViewAction, PoModalComponent, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { MainService } from 'src/app/app.service';
import { Actions } from 'src/app/classes/Actions';
import { Database } from 'src/app/classes/Database';
import { Hosts } from 'src/app/classes/Hosts';
import { Machine } from 'src/app/classes/Machine';
import { Software } from 'src/app/classes/Software';
import { Users } from 'src/app/classes/Users';
import { DatabaseService } from '../../database/database.service';
import { HostsService } from '../hosts/hosts.service';
import { SoftwareService } from '../software/software.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {

  @ViewChild('historyModal', {static: false}) historyModal: PoModalComponent;
  @ViewChild('detailModal', {static: false}) detailModal: PoModalComponent;

  constructor(public mainService: MainService, 
              public databaseService: DatabaseService, 
              public hostsService: HostsService, 
              public softwareService: SoftwareService,
              public cd: ChangeDetectorRef,
              private ngZone: NgZone,
              private readonly tracker: MatomoTracker,) { }

  private unsubscribe$ = new Subject<void>();

  resumeStatus: any[] = [];

  oracleBases: any[] = [
    { type: 'Banco de Dados', total: 80},
    { type: 'Protheus', total: 35}
  ];

  postgresBases: any[] = [
    { type: 'Banco de Dados', total: 70},
    { type: 'Protheus', total: 23}
  ];
  mssqlBases: any[] = [
    { type: 'Banco de Dados', total: 40},
    { type: 'Protheus', total: 15}
  ];

  filterCreated: number = 0;
  spotsFree: number = 0;
  spotsNumber: number = 0;

  oracleCreated: number = 0;
  oracleTotal: number = 0;
  oracleFiltered: any[] = [];

  postgresCreated: number = 0;
  postgresTotal: number = 0;
  postgresFiltered: any[] = [];

  mssqlCreated: number = 0;
  mssqlTotal: number = 0;
  mssqlFiltered: any[] = [];

  machinesToChart : Array<Object> = [];

  SpotTypeChart: PoChartType = PoChartType.Bar;

  MachineChart: PoChartType = PoChartType.Bar;
  MachineChartOptions: PoChartOptions = {
    axis: {
      maxRange: 100,
      gridLines: 5
    }
  };

  totalType: PoChartType = PoChartType.Column;;

  totalSeries: Array<PoChartSerie> = [];

  totalCategories: Array<string> = ['Database', 'Protheus'];

  totalOptions: PoChartOptions = {
    axis: {
      minRange: 0,
      gridLines: 5
    }
  };

  DBProtheusType: PoChartType = PoChartType.Pie;

  DBProtheusSeries: Array<PoChartSerie> = [];

  last24HType: PoChartType = PoChartType.Pie;

  last24HSeries: Array<PoChartSerie> = [];

  last7DaysType: PoChartType = PoChartType.Line;

  last7DaysSeries: Array<PoChartSerie> = [];
  last7DaysCategories: any[] = [];
  // last7DaysCategories: Array<string> = ['2010', '2011', '2012', '2013', '2014', '2015'];
  last7DaysOptions: PoChartOptions = {
    axis: {
      minRange: 0,
      gridLines: 4
    }
  };
  // 
  labelFilter: string = '';
  hostsNumber: number = 0;
  loading: boolean = false;
  resources: any[] = [];
  software: any[] = [];
  users: any[] = [];
  receitas: any[] = [];
  spots: any[] = [];
  spotsFiltered: any[] = [];
  hosts: any[] = [];
  history: any[] = [];

  usersItem: any[] = [];
  usersDetail: any[] = [];
  usersTemp: any[] = [];
  usersTempCount: number = 0;
  usersIndex: number = 0;

  usersFinal: any[] = [];
  userArea: string = '';
  username: string = '';
  userAtvRes: number = 0;
  userDelRes: number = 0;

  softwareIndex: number = 0;
  softwareName: string = '';

  resourceAreaColumns: Array<PoTableColumn>
  resourceAreaItem: Array<any> = [];
  resourceAreaTempCount: number = 0;
  resourceAreaIndex: number = 0;
  resourceAreaActions: Array<PoTableAction> = [
    { action: this.getLogByUsername.bind(this), label: 'Logs' },
    { action: this.getResourceByUsername.bind(this), label: 'Instâncias' }
  ];

  resourceColumns: Array<PoTableColumn>  = [
    { property: 'area', label: 'Área', type: 'string', width: '70%' },
    { property: 'qtdActive', label: 'Qtd. Ativa', type: 'string', width: '15%' },
    { property: 'qtd', label: 'Total', type: 'number', width: '15%' }
  ];
  resourceItem: Array<any> = [];
  resourceTempCount: number = 0;
  activeResourceTempCount: number = 0;
  resourceIndex: number = 0;
  resourceActions: Array<PoTableAction> = [
    //{ action: this.getLogByUsername.bind(this), label: 'Logs' },
    { action: this.getResourceByUsername.bind(this), label: 'Detalhes' }
  ];

  columnsDetails: Array<PoTableColumn>;
  itemsDetails: Array<any>;
  titleDetailsModal: string = '';

  readonly eventsObject: Array<PoListViewAction> = [
    { label: 'Modify', action: this.modifynst.bind(this) },
    { label: 'Delete', action: this.deleteInst.bind(this) }
    //  { label: 'Importar/Exportar Dados', action: this.dataOptionsInst  }
  ];

  waitTime: number = 0;

  @ViewChild('detailsModal', {static: false}) detailsModalElement: PoModalComponent;

  ngOnInit() {
    this.tracker.trackPageView('AdminPanel');
    this.getLocalValues();
    this.loading = true;
    this.getUsers();
    this.getSoftware();
    this.getHosts();
    // this.getSpots();
    this.getDBMachineResume();
    this.tracker.trackPageView('AdminPanel');
    
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  getLocalValues() {

    this.resourceColumns = [
      { property: 'area', label: 'Área', type: 'string', width: '70%' },
      { property: 'qtdActive', label: 'Qtd. Ativa', type: 'string', width: '15%' },
      { property: 'qtd', label: 'Total', type: 'number', width: '15%' }
    ];

    this.resourceAreaColumns = [
      { property: 'area', label: 'Área', type: 'string' },
      { property: 'qtdActive', label: 'Qtd. Ativa', type: 'string' },
      { property: 'qtd', label: 'Total', type: 'number' }
    ];
  }

  getUsers() {
    this.users = [ ];
    this.mainService.getUsers()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const userTemp = new Users();
              userTemp.Username = element.username;
              userTemp.Area = element.area;

              this.users = [...this.users, userTemp];
              this.usersTemp = [...this.usersTemp, {
                username: userTemp.Username,
                area: userTemp.Area
              }];

              this.usersIndex = this.usersItem.find( obj => obj.category === userTemp.Area );
              this.usersIndex = this.usersItem.indexOf(this.usersIndex);
              this.usersTempCount = 1;

              if (this.usersIndex >  -1 ) {
                this.usersItem[this.usersIndex].value += 1;
              } else {
                this.usersItem = [...this.usersItem, {
                    category: userTemp.Area ,
                    value: this.usersTempCount,
                    tooltip: userTemp.Area}];
              }
            });
            this.ngZone.run(() => {this.getResources();});
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
  }

  getResources() {
    this.receitas = [ ];
    this.resourceTempCount = 0 ;
    this.activeResourceTempCount = 0 ;
    this.databaseService.getAllDatabases()
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const resourceTemp = new Database();
              resourceTemp.id = element.id;
              resourceTemp.login = element.username;
              resourceTemp.name = element.name;
              resourceTemp.status = element.status;
              this.resources = [...this.resources, resourceTemp];

              if ( element.status === 'active') {
                if (element.softwareType === 'ORACLE') {
                  this.oracleFiltered = [...this.oracleFiltered, resourceTemp];
                } else {
                  if (element.softwareType === 'POSTGRES') {
                    this.postgresFiltered = [...this.postgresFiltered, resourceTemp];
                  } else {
                    if (element.softwareType === 'MSSQL') {
                      this.mssqlFiltered = [...this.mssqlFiltered, resourceTemp];
                    }
                  }
                }
              }

              this.username = resourceTemp.login;
              this.usersIndex = this.usersTemp.find( obj => obj.username === resourceTemp.login );
              this.usersIndex = this.usersTemp.indexOf(this.usersIndex);
              
              if (this.usersIndex >  -1 ) {
                if ( this.usersTemp[this.usersIndex].area === undefined || this.usersTemp[this.usersIndex].area === '' ) {
                  this.userArea = 'Indefinido';
                } else {
                  this.userArea = this.usersTemp[this.usersIndex].area;
                }
              } else {
                this.userArea = 'Indefinido';
              }

              this.resourceIndex = this.resourceItem.find(obj => obj.area === this.userArea);
              this.resourceIndex = this.resourceItem.indexOf(this.resourceIndex);

              if (this.resourceIndex >  -1 ) {
                if (resourceTemp.status === 'deleting' || resourceTemp.status === 'deleted' || resourceTemp.status === 'failed' ) {
                  this.resourceItem[this.resourceIndex].qtd += 1;
                  this.resourceTempCount += 1;
                  this.userDelRes = 1;
                } else {
                  this.resourceItem[this.resourceIndex].qtd += 1;
                  this.resourceItem[this.resourceIndex].qtdActive += 1;
                  this.resourceTempCount += 1;
                  this.activeResourceTempCount += 1;
                  this.userAtvRes = 1;
                }
              } else {
                if (resourceTemp.status === 'deleting' || resourceTemp.status === 'deleted' || resourceTemp.status === 'failed' ) {
                  this.resourceItem = [...this.resourceItem, { area: this.userArea , qtdActive: 0, qtd: 1 }];
                  this.resourceTempCount += 1;
                  this.userDelRes = 1;
                } else {
                  this.resourceItem = [...this.resourceItem, { area: this.userArea , qtdActive: 1, qtd: 1 }];
                  this.resourceTempCount += 1;
                  this.activeResourceTempCount += 1;
                  this.userAtvRes = 1;
                }
              }

            // Montagem do array que armazena usuario x area x resources ativos e inativos
              this.usersIndex = this.usersFinal.find( obj => obj.username === this.username );
              this.usersIndex = this.usersFinal.indexOf(this.usersIndex);
              
              if (this.usersIndex >  -1 ) {
                this.usersFinal[this.usersIndex].atvres += this.userAtvRes;
                this.usersFinal[this.usersIndex].delres += this.userDelRes;
              } else {
                this.usersFinal = [...this.usersFinal, {
                  username: this.username,
                  area: this.userArea,
                  atvres: this.userAtvRes,
                  delres: this.userDelRes
                }];
              }
              this.userArea = '';
              this.username = '';
              this.userAtvRes = 0;
              this.userDelRes = 0;

            }); 
            this.cd.detectChanges(); 
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
        this.cd.detectChanges(); 
  }

  clearAnalyzeValues(){
    this.resumeStatus = [];
    this.oracleTotal = 0
    this.postgresTotal = 0;
    this.mssqlTotal = 0;
    this.machinesToChart = [];
    this.last7DaysSeries = [];
    this.DBProtheusSeries = [];

  }
  
  getDBMachineResume() {
    this.loading = true;
    this.clearAnalyzeValues();
    this.mainService.getResumeAnalyzeDBs()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              this.analyzeByTime(element.timeAnalysis);
              this.cd.detectChanges();  
        
              this.analyzeByStatus(element.statusAnalysis);
              this.cd.detectChanges(); 
        
              this.analyzeByMachine(element.machinesAnalysis);
              this.cd.detectChanges(); 
              
            });
            this.loading = false;
            this.cd.detectChanges();     

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
  }

  runAnalyzeDBs() {
    this.loading = true;
    this.clearAnalyzeValues();
    this.mainService.runAnalyzeDBs()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              this.analyzeByTime(element.timeAnalysis);
              this.cd.detectChanges();  
        
              this.analyzeByStatus(element.statusAnalysis);
              this.cd.detectChanges(); 
        
              this.analyzeByMachine(element.machinesAnalysis);
              this.cd.detectChanges(); 
              
            });
            this.loading = false;
            this.cd.detectChanges();     
                      
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
  }

  analyzeByTime(timeAnalyses) {
    timeAnalyses.forEach( time => {
      if (time.period === 'D-1') {
        let totalProtheus = time.resume.PROTHEUS.ORACLE + time.resume.PROTHEUS.POSTGRES + time.resume.PROTHEUS.MSSQL;
        this.last24HSeries = [
          { label: 'Oracle', data: time.resume.DATABASE.ORACLE},
          { label: 'Postgres', data: time.resume.DATABASE.POSTGRES},
          { label: 'MSSQL', data: time.resume.DATABASE.MSSQL },
          { label: 'Protheus', data: totalProtheus }
        ];
      }

      if (time.period === 'D-7') {
        let oracleData: number[] = [];
        let postgresData: number[] = [];
        let mssqlData: number[] = [];
        let protheusData: number[] = [];
        time.resumeByDays.forEach( timeDay => {
          this.last7DaysCategories = [...this.last7DaysCategories, timeDay.day];

          const protheusTotalByDay = timeDay.types.PROTHEUS.ORACLE + timeDay.types.PROTHEUS.POSTGRES + timeDay.types.PROTHEUS.MSSQL;

          oracleData.push(timeDay.types.DATABASE.ORACLE);
          postgresData.push(timeDay.types.DATABASE.POSTGRES);
          mssqlData.push(timeDay.types.DATABASE.MSSQL);
          protheusData.push(protheusTotalByDay);

        });
        this.last7DaysSeries = [ 
          { label: "Oracle", data: oracleData },
          { label: "Postgres", data: postgresData },
          { label: "MSSQL", data: mssqlData },
          { label: "Protheus", data: protheusData} ]
      };
    })
  };

  analyzeByStatus(statusAnalyses) {
    statusAnalyses.forEach( status => {
      if (status.status.includes('active')) {
        this.totalSeries = [
          { label: 'Oracle', data: [status.resume.DATABASE.ORACLE, status.resume.PROTHEUS.ORACLE], type: PoChartType.Column},
          { label: 'Postgres', data: [status.resume.DATABASE.POSTGRES, status.resume.PROTHEUS.POSTGRES], type: PoChartType.Column},
          { label: 'MSSQL', data: [status.resume.DATABASE.MSSQL, status.resume.PROTHEUS.MSSQL], type: PoChartType.Column }
        ];
        const countDatabase = status.resume.DATABASE.ORACLE + status.resume.DATABASE.POSTGRES + status.resume.DATABASE.MSSQL;
        const countProtheus = status.resume.PROTHEUS.ORACLE + status.resume.PROTHEUS.POSTGRES + status.resume.PROTHEUS.MSSQL;
        this.DBProtheusSeries = [
          { label: 'Banco de Dados', data: countDatabase},
          { label: 'Protheus', data: countProtheus }
        ];
        this.oracleCreated = status.resume.DATABASE.ORACLE + status.resume.PROTHEUS.ORACLE;
        this.postgresCreated = status.resume.DATABASE.POSTGRES + status.resume.PROTHEUS.POSTGRES;
        this.mssqlCreated = status.resume.DATABASE.MSSQL + status.resume.PROTHEUS.MSSQL;;
      }
    })
  };

  analyzeByMachine(machineAnalyses) {
    machineAnalyses.forEach(machine => {
      let machineTemp = new Machine();
      machineTemp.series = [];
      machineTemp.category = [];
      machineTemp.id = machine._id;
      machineTemp.title = machine.hostName;
      machineTemp.ip = machine.hostIP;
      machineTemp.softwareType = machine.softwareType;
      machineTemp.total = machine.total;
      
      switch (machine.softwareType) {
        case 'ORACLE':
          machineTemp.created = machine.resume.DATABASE.ORACLE + machine.resume.PROTHEUS.ORACLE;
          this.oracleTotal += machine.total;
          break;
        
        case 'POSTGRES':
          machineTemp.created = machine.resume.DATABASE.POSTGRES + machine.resume.PROTHEUS.POSTGRES;
          this.postgresTotal += machine.total;
          break;

        case 'MSSQL':
          machineTemp.created = machine.resume.DATABASE.MSSQL + machine.resume.PROTHEUS.MSSQL;
          this.mssqlTotal += machine.total;
          break;
      }
      machineTemp.category.push('%')
      machineTemp.series.push({
        label: '% de ocupação',
        data: [Number(((machineTemp.created/machineTemp.total)*100))]
        
      })
      machineTemp.usage = Number(((machineTemp.created/machineTemp.total)*100).toFixed(1)).toString();
      this.machinesToChart.push(machineTemp)
    })
  };

  getSoftware() {
    this.software = [ ];
    this.softwareService.getSoftware()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const softwareTemp = new Software();
              softwareTemp.id = element.id;
              softwareTemp.name = element.name;
              softwareTemp.category = element.category;
              softwareTemp.version = element.version;

              this.software.push(softwareTemp);
            });
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
  }

  getHosts() {
    this.hosts = [ ];
    this.hostsNumber = 0;
    this.hostsService.getHosts()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const hostsTemp = new Hosts();
              hostsTemp.id = element.id;
              hostsTemp.name = element.name;
              hostsTemp.ip = element.ip;
              hostsTemp.availability = element.availability;
              hostsTemp.createdAt = element.createdAt;
              hostsTemp.updatedAt = element.updatedAt;
              this.hosts.push(hostsTemp);
              this.hostsNumber += 1;
            });
            
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
  }

  // getSpots() {
  //   this.spots = [ ];
  //   this.spotsNumber = 0;
  //   this.spotsFree = 0;
  //   this.spotsService.getSpots()
  //     .pipe(debounceTime(this.waitTime))
  //     .subscribe(
  //           res => {
  //             const retorno: any = res;
  //             retorno.forEach(element => {

  //               this.softwareName = this.softwareService.getSoftwareName(element.softwareId, this.software);

  //               const spotsTemp = new Spots();
  //               spotsTemp.id = element.id;
  //               spotsTemp.ip = element.ip;
  //               spotsTemp.port = element.port;
  //               spotsTemp.type = element.type;
  //               spotsTemp.softwareId = element.softwareId;
  //               spotsTemp.softwareName = this.softwareName;
  //               spotsTemp.hostId = this.hostsService.getHostName(element.hostId, this.hosts);
  //               spotsTemp.availability = element.availability;
  //               spotsTemp.createdAt = element.createdAt;
  //               spotsTemp.updatedAt = element.updatedAt;
  //               this.spots.push(spotsTemp);

  //               this.spotsNumber += 1;
  //               if ( spotsTemp.availability ) {
  //                 this.spotsFree += 1;
  //               }
  //             });
  //           },
  //           error => { this.loading = false; }
  //       );
  // }

  modifynst() {
    alert('Alterada!');
  }

  deleteInst() {
    alert('Deletada!');
  }

  getLogByUsername(userSelected) {
    this.history = [ ];
    this.mainService.getActionsbyName(userSelected['user'])
        .pipe(debounceTime(this.waitTime))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((history) => {
            history.forEach(element => {

              const historyTemp = new Actions();
              historyTemp.Id = element.id;
              historyTemp.resourceId = this.databaseService.getDatabaseName(element.resourceId, this.resources);
              historyTemp.recipeId = element.recipeId;
              historyTemp.actionType = element.actionType;
              historyTemp.username = element.username;
              historyTemp.description = element.description;
              historyTemp.createdAt = element.createdAt.replace('T', ' - ').split('.', 1);
              historyTemp.updatedAt = element.updatedAt.replace('T', ' - ').split('.', 1);

              this.history.push(historyTemp);
            });
            
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
        
      this.titleDetailsModal = userSelected['user'];
      this.columnsDetails = [
        { property: 'resourceId', label: 'Resource', type: 'string' },
        { property: 'actionType', label: 'Açao', type: 'string' },
        { property: 'username', label: 'Username', type: 'string' },
        { property: 'description', label: 'Descrição', type: 'string' },
        { property: 'createdAt', label: 'Created at', type: 'string' },
        { property: 'updatedAt', label: 'Updated at', type: 'string' }
      ];
      this.historyModal.open();
  }

  getResourceByUsername(userSelected) {
    this.usersDetail = [];
    this.usersFinal.forEach( element => {
      if ( element.area === userSelected['area'] ) {
        this.usersDetail.push({
          username: element.username,
          area: element.area,
          atvres: element.atvres,
          delres: element.delres
        });
      }
    });
    this.titleDetailsModal = userSelected['area'];
      this.columnsDetails = [
        { property: 'username', label: 'Usuario', type: 'string' },
        { property: 'area', label: 'Área', type: 'string' },
        { property: 'atvres', label: 'Qtd Ativa', type: 'string' },
        { property: 'delres', label: 'Qtd Inativa', type: 'string' }
      ];
      this.detailModal.open();
  }

  private spotsFilter(filters = [this.labelFilter]) {
    this.spotsFiltered = this.spots.filter(item => {
      return Object.keys(item)
      .some(key => (!(item[key] instanceof Object) && this.includeFilter(item[key], filters)));
    });
  }

  private includeFilter(item, filters) {
    return filters.some(filter => String(item).toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  }

  openModal(type) {
    switch (type) {
      case 'hosts':
        this.titleDetailsModal = 'Hosts';
        this.columnsDetails = [
          { property: 'id', label: 'ID', type: 'string' },
          { property: 'name', label: 'Name', type: 'string' },
          { property: 'ip', label: 'IP', type: 'string' },
          { property: 'availability', label: 'Status', type: 'string' },
          { property: 'createdAt', label: 'Created at', type: 'string' },
          { property: 'updatedAt', label: 'Updated at', type: 'string' }
        ];
        this.itemsDetails = this.hosts;
        this.detailsModalElement.open();
        break;

      case 'spots':
        this.titleDetailsModal = 'Spots Ativos';
        this.columnsDetails = [
          { property: 'id', label: 'ID', type: 'string' },
          { property: 'ip', label: 'IP', type: 'string' },
          { property: 'port', label: 'Port', type: 'string' },
          { property: 'type', label: 'Type', type: 'string' },
          { property: 'software', label: 'Software', type: 'string' },
          { property: 'hostId', label: 'HostID', type: 'string' }
        ];
        this.spotsFilter(['true']);
        this.itemsDetails = this.spotsFiltered
        this.detailsModalElement.open();
        break;
      
      case 'oracle':
        this.titleDetailsModal = 'Oracle';
        this.columnsDetails = [
          { property: 'type', label: 'Tipo', type: 'string' },
          { property: 'total', label: 'Total', type: 'number' }
        ];
        this.itemsDetails = this.oracleBases;
        this.detailsModalElement.open();
        break;

      case 'postgres':
        this.titleDetailsModal = 'Postgres';
        this.columnsDetails = [
          { property: 'type', label: 'Tipo', type: 'string' },
          { property: 'total', label: 'Total', type: 'number' }
        ];
        this.itemsDetails = this.postgresBases;
        this.detailsModalElement.open();
        break;

      case 'mssql':
        this.titleDetailsModal = 'MSSQL';
        this.columnsDetails = [
          { property: 'type', label: 'Tipo', type: 'string' },
          { property: 'total', label: 'Total', type: 'number' }
        ];
        this.itemsDetails = this.mssqlBases;
        this.detailsModalElement.open();
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
