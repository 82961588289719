import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { JamesbRouting } from './jamesb.routing';
import { JamesbComponent } from './jamesb.component';
import { PoModule } from '@po-ui/ng-components';
import { JamesbService } from './jamesb.service';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    PoModule,
    JamesbRouting
  ],
  declarations: [JamesbComponent],
  providers: [
    JamesbService
  ]
})
export class JamesbModule { }
