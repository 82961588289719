export class Database {
    public id: string;
    public name: string;
    public username: string;
    public area: string;
    public status: string;
    public statusType: string;
    public nStatus: number;
    public expirationDate: string;
    public remainingTime: number;
    public role: string;
    public login: string;
    public softwareType: string;
    public owner: string;
    public shared: string[];
    public metadata: object;
    public alias: string;
    public dbipporta: string;
    public dbaccess: string;
    public instanceType: string;
    public spotId: string;
    public kind?: string;
    public password?: string;
    public visualPassword?: string;
    public active: boolean;
    public createdAt: string;
    public updatedAt: string;
}
