import { Injectable } from '@angular/core';
import { MainService } from 'src/app/app.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, retry } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SoftwareService {

  constructor( private _http: HttpClient, private mainService: MainService) {
  }

  getSoftware(): Observable<any> {
    return this._http.get('/api/v1/software', this.mainService.httpOptions )
                .pipe(
                        retry(3),
                        map(software => {
                            return software;
                        }),
                        catchError(this.mainService.handleError));
  }

  getSoftwareName(id: string, software: Array<any>) {
      let softwareIndex;
      let softwareName;
      softwareIndex = software.find(obj => obj.id === id);
      softwareIndex = software.indexOf(softwareIndex);
      if ( softwareIndex > -1 ) {
          softwareName = software[softwareIndex].name + ' - ' + software[softwareIndex].version;
      } else {
          softwareName = 'NOT FOUND';
      }
      return softwareName;
  }
}
