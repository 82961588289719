import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { JamesbComponent } from './jamesb.component';

const routes: Routes = [
  { path: '', component: JamesbComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class JamesbRouting { }
