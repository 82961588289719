export class Binarios {
    public id: string;
    public release: string;
    public appserver_version: string;
    public appserver_image: string;
    public appserver_build: string;
    public dbaccess_version: string;
    public dbaccess_image: string;
    public published: boolean;
}
