import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-totvs-news-preview',
  templateUrl: './totvs-news-preview.component.html',
  styleUrls: ['./totvs-news-preview.component.css']
})
export class TotvsNewsPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
