import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { MainService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { Queries } from '../query-analyzer/models/queries.model';
import { QueryDocument } from './services/queryDocument';

@Injectable({
  providedIn: 'root'
})
export class QueryManagerService {

  private readonly API = environment.API;
  
  constructor(private http: HttpClient, private mainService: MainService) { }

  getQuery(type='SELECT_AST', today=true, days=null) {
    let url = `${this.API}search-query?type=${type}`;
    
    today ? url = url + '&today=1': null;
    days ? url = url + `&days=${days}`:null;

    return this.http.get<any>(url)
    .pipe();
  }
  
  postQuery(body: object) {
    return this.http.post<any>(`${this.API}search-query`, body, this.mainService.httpOptions)
    .pipe(
      // tap(console.log)
    );
  }

  getRulesQueries() {
    return [
      {"label":"D_E_L_E_T_AST","name":"D_E_L_E_T_ <> '*'"},
      {"label":"WHERE_CONCAT","name":"WHERE C/ CONCATE"}
    ]
  }

  reportQuery(queryDoc: QueryDocument) {
    return this.http.post<Queries>(`${this.API}report-query`, {
      '_id': String(queryDoc._id),
      'squad': String(queryDoc.squad),
      'source': String(queryDoc.source),
      'func': String(queryDoc.func),
      'line': String(queryDoc.line),
      'query': btoa(String(queryDoc.query)),
      'modulo': String(queryDoc.modulo),
      'planning': String(queryDoc.planning),
      'area': String(queryDoc.area),
      'user': String(queryDoc.user)
    }, this.mainService.httpOptions)
    .pipe(
      tap(console.log)
    )
  }
}
