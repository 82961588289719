import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { LoginModule } from './login/login.module';

import { MainService } from './app.service'
import { MenuModule } from './menu/menu.module';
import { FormsModule } from '@angular/forms';
import { ProtheusService } from './menu/protheus/protheus.service';
import { PoCodeEditorModule } from '@po-ui/ng-code-editor';
import { JamesbModule } from './menu/jamesb/jamesb.module';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PoModule,
    PoTemplatesModule,
    FormsModule,
    LoginModule,
    MenuModule,
    PoCodeEditorModule,
    JamesbModule,
    HttpClientModule,
    NgxMatomoTrackerModule.forRoot({ trackerUrl: '//10.171.80.34:9603/', siteId: '1' }),
    NgxMatomoRouterModule
    
  ],
  providers: [
    MainService,
    ProtheusService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
