import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Queries } from '../models/queries.model';
import { Filter } from './filter';
import { TestQuery } from './testQuery';
import { Summary } from '../models/summary.model';
import { Query } from '../models/query.model';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  private readonly API = environment.API;

  public httpOptions = {
    headers : new HttpHeaders ({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  count() {
    return this.http.get<any>(`${this.API}count`)
      .pipe(
        //tap(console.log)
      )
  }

  list() {
    return this.http.get<Queries>(`${this.API}list-query`)
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  getPorjects() {
    return this.http.get<Summary>(`${this.API}summary-projects`)
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  getPorject(squad) {
    return this.http.get<Summary>(`${this.API}summary-project?squad=${squad}`)
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  getSources(squad) {
    return this.http.get<Summary>(`${this.API}summary-sourcesbysquad?squad=${squad}`)
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  getSource(source) {
    return this.http.get<Summary>(`${this.API}summary-source?source=${source}`)
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  getQueries(source) {
    return this.http.get<Queries>(`${this.API}list-queries?source=${source}`)
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  getQuery(id_query) {
    return this.http.get<Query>(`${this.API}list-query?id=${id_query}`)
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  sendQuery(testQuery: TestQuery) {
    return this.http.post<Queries>(`${this.API}testQuery`, {
      'query': btoa(String(testQuery.query)),
      'oracle': String(testQuery.sgbds.oracle),
      'mssql': String(testQuery.sgbds.mssql),
      'postgres': String(testQuery.sgbds.postgres),
      'advpl': String(testQuery.method.advpl),
      'database': String(testQuery.method.database),
      'smart': String(testQuery.method.smart),
      'username': String(testQuery.user.username),
      'area': String(testQuery.user.area),
    }, this.httpOptions)
    .pipe(
      // tap(console.log)
    )
  }

  listPag(skip: number, limit: number, filter?: Filter) {
    let params
    if (filter) {
      params = {
        params: {
          'sgbd': String(filter.sgbd).toUpperCase() || '',
          'oraIsApproved': String(filter.oraIsApproved) || '',
          'mssqlIsApproved': String(filter.mssqlIsApproved) || '',
          'postIsApproved': String(filter.postIsApproved) || '',
          'oraMessage': String(filter.oraMessage) || '',
          'mssqlMessage': String(filter.mssqlMessage) || '',
          'postMessage': String(filter.postMessage) || '',
          'func': String(filter.func).toUpperCase() || '',
          'query': String(filter.query).toUpperCase() || ''
        }
      }
    }
    return this.http.get<Queries>(`${this.API}pagination-query/${String(skip)}/${String(limit)}`, { params })
      .pipe(
        //delay(100),
        //tap(console.log)
      );
  }

  getATUSXMetadado() {

    return this.http.get(`${this.API}atusx-metadado`)
      .pipe(
        // tap(console.log)
      )
  }

}