export class Actions {
    public Id: number;
    public resourceId: string;
    public recipeId: string;
    public actionType: string;
    public username: string;
    public description: string;
    public createdAt: string;
    public updatedAt: string;
}
