<!-- <div *ngIf="show" [class.show]="show" class="fullSize">
    <div class="toaster" style="background-color: #def7ed;">
        <div class="icon-success"><span class="po-icon po-icon-ok"></span></div>
        {{ message }}
    </div>
</div> -->

<div *ngIf="show" [class.show]="show" class="toaster show" [ngStyle]="{'background-color': toasterBackgroundColor}">
    <div class="toaster-content">
      <div class="toaster-icon" [ngStyle]="{'background-color': toasterIconBackgroundColor}">
        <span [class]="icon"></span>
      </div>
      <div class="toaster-message">
        {{ message }}
      </div>
      <div class="toaster-close" (click)="closeToaster()">
        <span class="po-icon po-icon-close"></span>
      </div>
    </div>
  </div>