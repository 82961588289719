<po-page-default p-title="Painel Administrativo">

    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>
    <div class="po-row">
  
      <po-button
        class="po-md-2"
        (p-click)="runAnalyzeDBs()"
        p-kind="primary"
        p-label="Atualizar Infos" >
      </po-button>
  
    </div>
    <br>
    <po-container class="po-lg-12">
  
  
      <div class="po-row">
        <po-container class="po-lg-12 po-mt-2"  id="divChart">
          
          <po-widget
            class="po-lg-3 po-mt-2"
            p-height="200"
            p-primary-label="Details"
            p-title="Spots"
            (p-primary-action)="openModal('spots')">
      
          <div class="po-font-subtitle po-text-center">{{ spotsFree }} / {{ spotsNumber }} spots livres</div>
          </po-widget>
  
          <po-widget
            class="po-lg-3 po-mt-2"
            p-height="200"
            p-primary-label="Details"
            p-title="Oracle"
            (p-primary-action)="openModal('oracle')">
      
          <div class="po-font-subtitle po-text-center">{{ oracleCreated }} / {{ oracleTotal }} bases criadas</div>
          </po-widget>
  
          <po-widget
            class="po-lg-3 po-mt-2"
            p-height="200"
            p-primary-label="Details"
            p-title="Postgres"
            (p-primary-action)="openModal('postgres')">
      
          <div class="po-font-subtitle po-text-center">{{ postgresCreated }} / {{ postgresTotal }} bases criadas</div>
          </po-widget>
  
          <po-widget
            class="po-lg-3 po-mt-2"
            p-height="200"
            p-primary-label="Details"
            p-title="MSSQL"
            (p-primary-action)="openModal('mssql')">
      
          <div class="po-font-subtitle po-text-center">{{ mssqlCreated }} / {{ mssqlTotal }} bases criadas</div>
          </po-widget>
          
        </po-container>
  
        <po-container class="po-lg-12 po-mt-2"  id="divChart">
          <div class="po-font-text-bold">Ocupação / Máquina</div>
          <div *ngFor="let machine of machinesToChart; let i = index">
            <po-chart
                class="po-md-3 po-mt-3"
                [p-title]="machine.title"
                [p-height]="100"
                [p-categories]="machine.category"
                [p-series]="machine.series"
                [p-type]="MachineChart"
                [p-options]="MachineChartOptions"
              >
              </po-chart>
          </div>
        </po-container>
      </div>
  
    </po-container>
    <po-divider></po-divider>
  
    <po-container class="po-lg-12">
      <div class="po-row">
        <po-container class="po-lg-12">
  
          <po-info
            class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
            p-label="Resources criados x Usuários">
          </po-info>
          
          <po-table
            p-container
            p-actions-right="true"
            p-height="300"
            p-sort="true"
            [p-columns]="resourceColumns"
            [p-items]="resourceItem"
            [p-actions]="resourceActions">
  
          </po-table>
  
          <po-divider></po-divider>
  
          <po-info
            class="po-md-6 po-mb-sm-2 po-mb-md-2 thf-lb-lg-2"
            p-label="Total"
            p-orientation="horizontal"
            p-value="{{activeResourceTempCount}} ativos">
          </po-info>
  
    </po-container>
  
    <po-divider></po-divider>
      <po-container class="po-lg-12 po-mt-2"  id="divChart">
        
        <po-chart
          class="po-lg-6 po-md-6 po-mt-2"
          p-title="Criações nas últimas 24h"
          [p-series]="last24HSeries"
          [p-type]="last24HType"
        >
        </po-chart>

        <po-chart
          class="po-lg-6 po-md-6 po-mt-2"
          p-title="Criações na última semana"
          [p-options]="last7DaysOptions"
          [p-categories]="last7DaysCategories"
          [p-series]="last7DaysSeries"
          [p-type]="last7DaysType"
        >
        </po-chart>

        <po-chart
          class="po-lg-6 po-md-6 po-mt-2"
          p-title="Total de bases"
          [p-options]="totalOptions"
          [p-categories]="totalCategories"
          [p-series]="totalSeries"
          [p-type]="totalType"
        >
        </po-chart>
  
        <po-chart
          class="po-lg-6 po-md-6 po-mt-2"
          p-title="Banco de Dados x Protheus"
          [p-series]="DBProtheusSeries"
          [p-type]="DBProtheusType"
        >
        </po-chart>
  
      </po-container>
    
    </div>
    </po-container>
  
  </po-page-default>
  
  <po-modal #detailsModal [p-title]="titleDetailsModal">
    <po-table
      [p-columns]="columnsDetails"
      [p-items]="itemsDetails">
    </po-table>
  </po-modal>
  
  <po-modal #historyModal 
    [p-title]="titleDetailsModal"
    p-click-out=true>
    <po-table
      [p-columns]="columnsDetails"
      [p-items]="history">
    </po-table>
  </po-modal>
  
  <po-modal #detailModal 
    [p-title]="titleDetailsModal"
    p-click-out=true>
    <po-table
      [p-columns]="columnsDetails"
      [p-items]="usersDetail">
    </po-table>
  </po-modal>