import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryAnalyzerComponent } from './query-analyzer.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { PoModule } from '@po-ui/ng-components';
import { QueryAnalyzerService } from './query-analyzer.service';
import { MainService } from 'src/app/app.service';
import { ChatbotAiComponent } from './components/chatbot-ai/chatbot-ai.component';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { PoCodeEditorModule } from '@po-ui/ng-code-editor';
import { TestQueryComponent } from './components/test-query/test-query.component';

@NgModule({
  declarations: [
    QueryAnalyzerComponent,
    ChatbotAiComponent,
    TestQueryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    PoModule,
    PoTemplatesModule,
    PoCodeEditorModule,
    NotificationItemComponent
  ],
  providers: [
    MainService,
    QueryAnalyzerService
  ]
})
export class QueryAnalyzerModule { }
