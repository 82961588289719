import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/app.service';
import { SupportService } from './support.service';
import { PoNotification, PoNotificationService } from '@po-ui/ng-components';


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent {
  ticket_subject: string = '';
  ticket_text: string = '';

  constructor(private router: Router,
    public mainService: MainService,
    private supportService: SupportService,
    public poNotificationService: PoNotificationService) { }

  open_ticket() {
    let canOpenReport = this.reportLimitAndCheckTimeout();
    if (canOpenReport) {
      this.supportService.createIssue(this.ticket_subject, this.ticket_text).subscribe({
        next: ((response) => {
          this.poNotificationService.success("Solicitação aberta com sucesso! Nossa equipe entrará em contato em breve.")
          this.ticket_subject = "";
          this.ticket_text = "";
          let timestamp = new Date().getTime();
          sessionStorage.setItem('report_discord', timestamp.toString());
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
          })
      });  
    }
    
  }

  reportLimitAndCheckTimeout() {
    if ( sessionStorage.getItem('report_discord') ){
      let timestamp = sessionStorage.getItem('report_discord') || '0';
      let time_before = parseInt( timestamp );
      let hour_now = new Date().getTime();
      let timePass = new Date(hour_now - time_before).getUTCMinutes();
      let timeLeft = 5-timePass;
      if ( timeLeft <= 0) {
          sessionStorage.removeItem('report_discord');
          return true;
      } else {
          this.poNotificationService.error('Limite de reports atingido! Próxima tentativa em ' +  timeLeft + ' minutos');
          return false;
      }
    } else {
      return true;
    }
}
}
