import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError, pipe } from 'rxjs';
import { MainService } from '../../app.service';

@Injectable()

export class JamesbService {

    constructor( private _http: HttpClient, private instservice: MainService) {
    }

    /*
    GET /jb/whitelist
    GET /jb/whitelist/:id
    POST /jb/whitelist
    PUT /jb/whitelist
    DELETE /jb/whitelist
    ah sim o put e o delete tem /:id no final
    GET /jb/config
    GET /jb/config/:id
    POST /jb/config
    PUT /jb/config/:id
    DELETE /jb/config/:id
    tem duas rotas de GET nos configs, mas é pro Protheus
    */

// - RESOURCES/INSTANCIAS ------------------------------------------------------------------------------------------
    getLiberacoes (): Observable<any> {
        return this._http.get('/api/v1/jb/whitelist', this.instservice.httpOptions )
                    .pipe(
                            retry(3),
                            map(instancias => {
                                return instancias;
                            }),
                            catchError(this.instservice.handleError));
    }

    postLiberacao (username, machineUser, remote, server, reason, area, expiracao): Observable<any> {
        return this._http
            .post('/api/v1/jb/whitelist',
                    JSON.stringify({username, machineUser, remote, server, reason, area, expiracao}), this.instservice.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.instservice.handleError)
            );
    }

}
