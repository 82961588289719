const sgdbDataSchema = {

    username: String,
    password: String,
    databaseId: String,
    type: String

};

const deployStatsDataSchema = {

    message: String,
    detail: Object
};

const artifactsSchema = {

    name: String,
    kind: String,
    ip: String,
    port: String,
    url: ''

};

export class Protheus {
    public id: string;
    public name: string;
    public username: string;
    public deployStats: string;
    public deployStatsData: typeof deployStatsDataSchema;
    public specs: Object;
    public sgdbData: typeof sgdbDataSchema;
    public specFinal_JSON: string;
    public artifacts: typeof artifactsSchema[];
    public shared:  string[];
    public metadata : string[];
    public sgdbDataResume: string;
    public releaseLocResume: string;
    public deployStatsDataResume: string;
    public statusType: string;
    public remainingTime: number;
    public originalIni: string;
    public modifiedIni: string;
    public active: boolean;
    public createdAt: string;
    public updatedAt: string;
}