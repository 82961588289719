import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../../app.service';
import { ProtheusAdminService } from './protheus-admin.service';
import { Binarios } from '../../../classes/Binarios';
import { DbAccess } from '../../../classes/DbAccess';
import { Localizacoes } from '../../../classes/Localizacoes';
import { Releases } from '../../../classes/Releases';
import { Rpo } from '../../../classes/Rpo';
import { Router } from '@angular/router';
import { Bases } from '../../../classes/Bases';
import { PoDynamicFormField, PoDynamicFormFieldChanged, PoModalAction, PoModalComponent, PoPageAction, PoPageFilter, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { Subject, takeUntil } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-protheus',
  templateUrl: './protheus-admin.component.html',
  styleUrls: ['./protheus-admin.component.css']
})
export class ProtheusAdminComponent implements OnInit {

  @ViewChild('criaprotheusObject', {static: false}) criaProtheusObject: PoModalComponent;
  @ViewChild('editProtheusObject', {static: false}) editProtheusObjectModal: PoModalComponent;
  @ViewChild('removeProtheusObject', {static: false}) removeProtheusObject: PoModalComponent;


  constructor(private router: Router,
    public mainService: MainService,
    private protheusService: ProtheusAdminService,
    private readonly tracker: MatomoTracker) { }

  loading = false;
  unsubscribe$ = new Subject<void>();

  protheus: Array<any> = [];
  protheusFiltered: Array<any> = [];
  releases: Array<any> = [];
  localizacoes: Array<any> = [];
  localizacoesFull: Array<any> = [];

  releaseVisible = false;
  relRequired = false;
  locVisible = false;
  locRequired = false;

  idiomas: [
    {label: "multi", value: "multi"},
    {label: "pt-br", value: "portuguese"},
    {label: "es-mx", value: "spanish"},
    {label: "en-us", value: "english"}
  ]

  _objectName = '';
  _id = '';
  _release = '';
  _appserver_version = '';
  _appserver_image = '';
  _dbaccess_version = '';
  _dbaccess_image = '';
  _published = false;
  _localizacao = '';
  _regional_language = '';
  _link_menu = '';
  _link_help = '';
  _link_dic = '';
  _idiomas = [];
  _name = '';
  _idioma = '';
  _valid = false;
  _version = '';
  _link_rpo = '';

  titulo = '';
  objetoSelec = ''

  public readonly actions: Array<PoPageAction> = [
    { label: 'Novo Objeto', action: this.createProtheusObjetct.bind(this) },
  ];

  confirm: PoModalAction = {
    action: () => {
      this.saveObjects();
      this.closeCreateModal();
    },
    label: 'Create'
  }

  edit: PoModalAction = {
    action: () => {
      this.saveEditObjects();
      this.editProtheusObjectModal.close();
    },
    label: 'Salvar'
  }

  closeEdit: PoModalAction = {
    action: () => {
      this.editProtheusObjectModal.close();
    },
    label: 'Close'
  }

  close: PoModalAction = {
    action: () => {
      this.closeCreateModal();
    },
    label: 'Close',
    danger: true
  };

  delete: PoModalAction = {
    action: () => {
      this.removeObjects();
      this.closeRemoveModal();
    },
    label: 'Delete'
  }
  closeDelete: PoModalAction = {
    action: () => {
      this.closeRemoveModal();
    },
    label: 'Close',
    danger: true
  };

  rule_fields: Array<PoDynamicFormField> = [  ];
  newrule: Array<any> = [];
  editrule: Array<any> = [];

  objetosP: Array<any> = [
    { label: 'Binarios', value: 'binarios' },
    { label: 'Localizacoes', value: 'localizacoes' },
    { label: 'Releases', value: 'releases'},
    { label: 'RPO', value: 'rpo' },
    { label: 'Bases', value: 'bases' }
  ];

  objetosPSelected;

  objetosPColumns: Array<PoTableColumn> = [ ]

  readonly eventsObject: Array<PoTableAction> = [
    { action: this.editProtheusObjetct.bind(this), label: 'Editar' },
    { action: this.removeProtheusObjetct.bind(this), label: 'Delete' }
  ];

  readonly filterSettings: PoPageFilter = {
    action: this.objetosPFilter.bind(this),
    // ngModel: 'labelFilter',
    placeholder: 'Search'
  };
  labelFilter: string = '';

  ngOnInit() {
    this.tracker.trackPageView('Protheus_Admin');
    this.titulo = 'Novo Release';
    this.objetoSelec = 'releases';
    this.objetosPSelected = 'releases';
    this.getReleaseArray()
    this.getLocalizacoesArray()
    this.getAllReleases();
  }

  onChangeobjetosP(objetopsPSelected) {
    this.objetoSelec = objetopsPSelected;

    if (objetopsPSelected === 'binarios') {
      this.releaseVisible = true;
      this.resetVisibleValues()
      this.getAllBinarios();
    }
    if (objetopsPSelected === 'localizacoes') {
      this.releaseVisible = true;
      this.resetVisibleValues()
      this.getAllLocalizacoes();
    }
    if (objetopsPSelected === 'releases') {
      this.resetVisibleValues();
      this.releaseVisible = false;
      this.relRequired = false;
      this.getAllReleases();
    }
    if (objetopsPSelected === 'rpo') {
      this.releaseVisible = true;
      this.locVisible = true;
      this.locRequired = true;
      this.getAllRPO();
    }
    if (objetopsPSelected === 'bases') {
      this.releaseVisible = true;
      this.locVisible = true;
      this.locRequired = true;
      this.getAllBases();
    }
  }

  resetVisibleValues(){
    this.releaseVisible = true;
    this.relRequired = true;
    this.locVisible = false;
    this.locRequired = false;
  }

  onChangeRelease(releaseSelected) {
    this.loading = true;
    this._localizacao = '';
    if ( this.locRequired ) {
      this.getLocalizacoesbyRelease(releaseSelected);
    } else {
      this.loading = false;
    }
  }

  getAllBinarios() {
    this.loading = true
    this.getBinariosFields();

    this.protheus = [ ];
    this.protheusService.getBinarios()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const protheusTemp = new Binarios();
              protheusTemp.id = element._id;
              protheusTemp.release = this.getReleaseName(element.release);
              protheusTemp.appserver_version = element.appserver_version;
              protheusTemp.appserver_image = element.appserver_image;
              protheusTemp.appserver_build = element.appserver_build;
              protheusTemp.dbaccess_version = element.dbaccess_version;
              protheusTemp.dbaccess_image = element.dbaccess_image;
              protheusTemp.published = element.published;
              this.protheus = [...this.protheus, protheusTemp];
              this.protheusFiltered = this.protheus;
            });
            this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
  }

  getAllLocalizacoes() {
    this.loading = true
    this.getLocalizacoesFields();

    this.protheus = [ ];
    this.protheusService.getLocalizacoes()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const protheusTemp = new Localizacoes();
              protheusTemp.id = element._id;
              protheusTemp.release = this.getReleaseName(element.release);
              protheusTemp.localizacao = element.locale;
              protheusTemp.regional_language = element.regional_language;
              protheusTemp.link_menu = element.link_menu;
              protheusTemp.link_help = element.link_help;
              protheusTemp.link_dic = element.link_dic;
              protheusTemp.congelada = element.congelada;
              protheusTemp.published = element.published;

              this.protheus = [...this.protheus, protheusTemp];
              this.protheusFiltered = this.protheus;
            });
            this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
  }

  getLocalizacoesbyRelease(releaseId) {
    this.localizacoes = [ ];
    this.protheusService.getLocalizacoesbyRelease(releaseId)
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const protheusTemp = new Localizacoes();
              protheusTemp.id = element._id;
              protheusTemp.release = element.release;
              protheusTemp.localizacao = element.locale;
              protheusTemp.regional_language = element.regional_language;
              protheusTemp.link_menu = element.link_menu;
              protheusTemp.link_help = element.link_help;
              protheusTemp.link_dic = element.link_dic;

              this.localizacoes = [...this.localizacoes, { label: this.getReleaseName(element.release) + ' - ' + element.locale, value: element._id }];
            });
            this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
  }

  getReleaseArray() {
    this.loading = true
    this.releases = [ ];
    this.protheusService.getReleases()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              this.releases = [...this.releases, { label: element.name, value: element._id }];
            });
            this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
  }

  getLocalizacoesArray() {
    this.loading = true
    this.localizacoesFull = [ ];
    this.protheusService.getLocalizacoes()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const locName = this.getReleaseName(element.release) + ' - ' + element.locale;
              this.localizacoesFull = [...this.localizacoesFull, { label: element.locale, value: element._id }];
            });
            this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });

  }

  getAllReleases() {
    this.loading = true
    this.getReleasesFields();
    this.protheus = [ ];
    this.protheusService.getReleases()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const protheusTemp = new Releases();
              protheusTemp.id = element._id;
              protheusTemp.name = element.name;
              protheusTemp.idioma = element.idiomas;
              protheusTemp.valid = element.valid;

              this.protheus = [...this.protheus, protheusTemp];
              this.protheusFiltered = this.protheus;

            });
            this.loading = false;
            this.getReleaseArray();

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
  }

  getAllRPO() {
    this.loading = true
    this.getRPOFields();

    this.protheus = [ ];
    this.protheusService.getRPOs()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const protheusTemp = new Rpo();
              protheusTemp.id = element._id;
              protheusTemp.release = this.getReleaseName(element.release);
              protheusTemp.version = element.version;
              protheusTemp.idioma = element.idioma;
              protheusTemp.localizacao = this.getLocName(element.localizacao);
              protheusTemp.link_rpo = element.link_rpo;
              protheusTemp.published = element.published;

              this.protheus = [...this.protheus, protheusTemp];
              this.protheusFiltered = this.protheus;

            });
            this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });

  }

  getAllBases() {
    this.loading = true
    this.getBaseFields();

    this.protheus = [ ];
    this.protheusService.getBases()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            retorno.forEach(element => {
              const protheusTemp = new Bases();
              protheusTemp.id = element._id;
              protheusTemp.release = this.getReleaseName(element.release);
              protheusTemp.localizacao = this.getLocName(element.localizacao);
              protheusTemp.tipo = element.tipo;
              protheusTemp.dbAlias = element.dbAlias;
              protheusTemp.recipeId = element.recipeId;
              protheusTemp.published = element.published;

              this.protheus = [...this.protheus, protheusTemp];
              this.protheusFiltered = this.protheus;

            });
            this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });

  }

  getBinariosFields(){

    this.titulo = 'Novo Binário';

    this.objetosPColumns = [
      { property: 'id', label: 'ID' },
      { property: 'release', label: 'Release' },
      { property: 'appserver_version', label: 'Appserver Version'},
      { property: 'appserver_build', label: 'Appserver Build' },
      { property: 'appserver_image', label: 'Appserver Image' },
      { property: 'dbaccess_version', label: 'Dbaccess Version' },
      { property: 'dbaccess_image', label: 'Dbaccess Image'},
      { property: 'published', label: 'Published' }
    ]

    this.rule_fields = [
      { property: 'appserver_version', label: 'Appserver Version', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'appserver_build', label: 'Appserver Build', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'appserver_image', label: 'Appserver Image', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'dbaccess_version', label: 'DbAccess Version', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'dbaccess_image', label: 'DbAccess Image', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'published', label: 'Published', required: true, type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    ];

  }

  getLocalizacoesFields() {

    this.titulo = 'Nova Localização';

    this.objetosPColumns = [
      { property: 'id', label: 'ID' },
      { property: 'release', label: 'Release' },
      { property: 'localizacao', label: 'Localizacao' },
      { property: 'regional_language', label: 'Regional Language'},
      { property: 'link_menu', label: 'Link Menu'},
      { property: 'link_help', label: 'Link Help' },
      { property: 'link_dic', label: 'Link Dic' },
      { property: 'congelada', label: 'Link Base Congelada' },
      { property: 'published', label: 'Published' }
    ]

    this.rule_fields = [
      { property: 'localizacao', label: 'Localização', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'regional_language', label: 'Regional Language', placeholder: 'Sigla país ex.: BRA, MEX, ...', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'link_menu', label: 'Link Menu', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'link_help', label: 'Link Help', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'link_dic', label: 'Link Dicionário', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'congelada', label: 'Link Base Congelada', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'published', label: 'Published', required: true, type: 'boolean', gridColumns: 6, gridSmColumns: 12 }
    ];

  }

  getReleasesFields() {

    this.titulo = 'Novo Release';

    this.objetosPColumns = [
      { property: 'id', label: 'ID'},
      { property: 'name', label: 'Release Name'},
      { property: 'idioma', label: 'Idioma'},
      { property: 'valid', label: 'Valid' }
    ]

    this.rule_fields = [
      { property: 'name', label: 'Nome', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'idioma', label: 'Idioma', required: true, gridColumns: 6, gridSmColumns: 12,
            options: [
                  { label: 'multi', value: 'multi'},
                  { label: 'pt-br', value: 'portuguese'},
                  { label: 'es-mx', value: 'spanish'},
                  { label: 'en-us', value: 'english'}], optionsMulti: true},
      { property: 'valid', label: 'Valid', required: true, type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    ];

  }

  getRPOFields() {

    this.titulo = 'Novo RPO';

    this.objetosPColumns = [
      { property: 'id', label: 'ID' },
      { property: 'release', label: 'Release' },
      { property: 'localizacao', label: 'Localização'},
      { property: 'version', label: 'Versão' },
      { property: 'idioma', label: 'Idioma' },
      { property: 'link_rpo', label: 'Link RPO' },
      { property: 'published', label: 'Published' }
    ]

    this.rule_fields = [
      { property: 'version', label: 'Versão', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'idioma', label: 'Idioma', required: true, gridColumns: 6, gridSmColumns: 12,
      options: [
            { label: 'multi', value: 'multi'},
            { label: 'pt-br', value: 'portuguese'},
            { label: 'es-mx', value: 'spanish'},
            { label: 'en-us', value: 'english'}], optionsMulti: false},
      { property: 'link_rpo', label: 'Link RPO', required: true, type: 'string', gridColumns: 6, gridSmColumns: 12 },
      { property: 'published', label: 'Published', required: true, type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    ];

  }

  getBaseFields() {
    this.titulo = 'Nova Base';

    this.objetosPColumns = [
      { property: 'id', label: 'ID' },
      { property: 'release', label: 'Release' },
      { property: 'localizacao', label: 'Localização'},
      { property: 'tipo', label: 'Tipo' },
      { property: 'dbAlias', label: 'DB Alias' },
      { property: 'recipeId', label: 'Receita ID' },
      { property: 'published', label: 'Published' }
    ]

    this.rule_fields = [
      { property: 'tipo', label: 'Tipo', required: true, options: [
                                                          { label: 'Inicializada', value: 'inicializada'},
                                                          { label: 'congelada', value: 'congelada'}], gridColumns: 6, gridSmColumns: 12 },
      { property: 'dbAlias', label: 'DB Alias', required: true, options: [
                                                          { label: 'POSTGRES', value: 'POSTGRES'},
                                                          { label: 'ORACLE', value: 'ORACLE'},
                                                          { label: 'MSSQL', value: 'MSSQL'}], gridColumns: 6, gridSmColumns: 12 },
      { property: 'recipeId', label: 'Receita ID', required: true, gridColumns: 6, gridSmColumns: 12 },
      { property: 'published', label: 'Published', required: true, type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    ];
  }

  createProtheusObjetct() {
    this.newrule = [];
    this.criaProtheusObject.open();
  }

  saveObjects() {

    if (this.objetoSelec === 'binarios' ) {
      this.protheusService.postBinario(this._release, this.newrule['appserver_version'], this.newrule['appserver_build'], this.newrule['appserver_image'],  this.newrule['dbaccess_version'], this.newrule['dbaccess_image'], this.newrule['published'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);

        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'localizacoes') {
      this.protheusService.postLocalizacoes(this._release, this.newrule['localizacao'], this.newrule['link_menu'], this.newrule['link_help'], this.newrule['link_dic'], this.newrule['congelada'], this.newrule['published'] || false, this.newrule['regional_language'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'releases') {
      this.protheusService.postReleases(this.newrule['name'], this.newrule['idioma'], this.newrule['valid'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'rpo') {
      this.protheusService.postRPOs(this._release, this.newrule['version'], this.newrule['idioma'], this._localizacao, this.newrule['link_rpo'], this.newrule['published'] || false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'bases') {
      this.protheusService.postBases(this._release, this._localizacao, this.newrule['tipo'], this.newrule['dbAlias'], this.newrule['recipeId'], this.newrule['published'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

  }

  editProtheusObjetct(objectSelected) {

    this.editrule = [];

    if (this.objetoSelec === 'binarios') {
      this._objectName = objectSelected['release'] + ' - ' + objectSelected['appserver_version'];
      this.titulo = 'Editar Binário';
      this._id = objectSelected['id'];
      this._release = objectSelected['release'];
      this.editrule['appserver_version'] = objectSelected['appserver_version'];
      this.editrule['appserver_build'] = objectSelected['appserver_build'];
      this.editrule['appserver_image'] = objectSelected['appserver_image'];
      this.editrule['dbaccess_version'] = objectSelected['dbaccess_version'];
      this.editrule['dbaccess_image'] = objectSelected['dbaccess_image'];
      this.editrule['published'] = objectSelected['published'];
    }

    if (this.objetoSelec === 'localizacoes') {
      this._objectName = objectSelected['release'] + ' - ' + objectSelected['localizacao'];
      this.titulo = 'Editar Localização';
      this._id = objectSelected['id'];
      this._release = objectSelected['release'];

      this.editrule['localizacao'] = objectSelected['localizacao'];
      this.editrule['regional_language'] = objectSelected['regional_language'];
      this.editrule['link_menu'] = objectSelected['link_menu'];
      this.editrule['link_help'] = objectSelected['link_help'];
      this.editrule['link_dic'] = objectSelected['link_dic'];
      this.editrule['congelada'] = objectSelected['congelada'];
      this.editrule['published'] = objectSelected['published'];
    }

    if (this.objetoSelec === 'releases') {
      this._objectName = objectSelected['name'] + ' - ' + objectSelected['idioma'];
      this.titulo = 'Editar Release';
      this._id = objectSelected['id'];
      this._release = objectSelected['release'];

      this.editrule['name'] = objectSelected['name'];
      this.editrule['idioma'] = objectSelected['idioma'];
      this.editrule['valid'] = objectSelected['valid'];
    }

    if (this.objetoSelec === 'rpo') {
      this._objectName = objectSelected['release'] + ' - ' + objectSelected['version'] + ' - ' + objectSelected['idioma'];
      this.titulo = 'Editar RPO';
      this._id = objectSelected['id'];
      this._release = this.getReleaseID(objectSelected['release']);
      this.onChangeRelease(this._release)
      this._localizacao = this.getLocID(objectSelected['release'] + ' - ' + objectSelected['localizacao']);
      this.editrule['version'] = objectSelected['version'];
      this.editrule['idioma'] = objectSelected['idioma'];
      this.editrule['link_rpo'] = objectSelected['link_rpo'];
      this.editrule['published'] = objectSelected['published'];

    }

    if (this.objetoSelec === 'bases') {
      this._objectName = objectSelected['release'] + ' - ' + objectSelected['localizacao'] + ' - ' + objectSelected['dbAlias'] + ' - ' + objectSelected['tipo'];
      this.titulo = 'Editar Base';
      this._id = objectSelected['id'];
      this._release = objectSelected['release'];
      this.editrule['tipo'] = objectSelected['tipo'];
      this.editrule['dbAlias'] = objectSelected['dbAlias'];
      this.editrule['recipeId'] = objectSelected['recipeId'];
      this.editrule['published'] = objectSelected['published'];
    }

    this.editProtheusObjectModal.open();

  }

  saveEditObjects(){

    if (this.objetoSelec === 'binarios' ) {
      this.protheusService.putBinario(this._id, this._release, this.editrule['appserver_version'], this.editrule['appserver_build'], this.editrule['appserver_image'],  this.editrule['dbaccess_version'], this.editrule['dbaccess_image'], this.editrule['published'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);

        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'localizacoes') {
      this.protheusService.putLocalizacoes(this._id, this._release, this.editrule['localizacao'], this.editrule['link_menu'], this.editrule['link_help'], this.editrule['link_dic'], this.editrule['congelada'], this.editrule['published'] || false, this.editrule['regional_language'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'releases') {
      this.protheusService.putReleases(this._id, this.editrule['name'], this.editrule['idioma'], this.editrule['valid'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'rpo') {
      this.protheusService.putRPOs(this._id, this._release, this.editrule['version'], this.editrule['idioma'], this._localizacao, this.editrule['link_rpo'], this.editrule['published'] || false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

    if (this.objetoSelec === 'bases') {
      this.protheusService.putBases(this._id, this._release, this._localizacao, this.editrule['tipo'], this.editrule['dbAlias'], this.editrule['recipeId'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          this.onChangeobjetosP(this.objetoSelec);
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    }

  }

  removeProtheusObjetct(objectSelected) {

      if (this.objetoSelec === 'binarios') {
        this._objectName = objectSelected['release'] + ' - ' + objectSelected['appserver_version'];
        this.titulo = 'Deletar Binário';
        this._id = objectSelected['id'];
      }

      if (this.objetoSelec === 'localizacoes') {
        this._objectName = objectSelected['release'] + ' - ' + objectSelected['localizacao'];
        this.titulo = 'Deletar Localização';
        this._id = objectSelected['id'];
      }

      if (this.objetoSelec === 'releases') {
        this._objectName = objectSelected['name'] + ' - ' + objectSelected['idioma'];
        this.titulo = 'Deletar Release';
        this._id = objectSelected['id'];
      }

      if (this.objetoSelec === 'rpo') {
        this._objectName = objectSelected['release'] + ' - ' + objectSelected['version'] + ' - ' + objectSelected['idioma'];
        this.titulo = 'Deletar RPO';
        this._id = objectSelected['id'];
      }

      if (this.objetoSelec === 'bases') {
        this._objectName = objectSelected['release'] + ' - ' + objectSelected['localizacao'] + ' - ' + objectSelected['dbAlias'] + ' - ' + objectSelected['tipo'];
        this.titulo = 'Deletar Base';
        this._id = objectSelected['id'];
      }

      this.removeProtheusObject.open();
  }

  removeObjects() {

    if (this.objetoSelec === 'binarios') {
      this.protheusService.deleteBinario(this._id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            this.onChangeobjetosP(this.objetoSelec);
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    }

    if (this.objetoSelec === 'localizacoes') {
      this.protheusService.deleteLocalizacao(this._id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            this.onChangeobjetosP(this.objetoSelec);
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    }

    if (this.objetoSelec === 'releases') {
      this.protheusService.deleteRelease(this._id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            this.onChangeobjetosP(this.objetoSelec);
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    }

    if (this.objetoSelec === 'rpo') {
      this.protheusService.deleteRPO(this._id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            this.onChangeobjetosP(this.objetoSelec);
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    }

    if (this.objetoSelec === 'bases') {
      this.protheusService.deleteBases(this._id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((retorno) => {
            this.onChangeobjetosP(this.objetoSelec);
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    }

  }

  private objetosPFilter(filters = [this.labelFilter]) {
    this.protheusFiltered = this.protheus.filter(item => {
      return Object.keys(item)
      .some(key => (!(item[key] instanceof Object) && this.includeFilter(item[key], filters)));
    });
  }

  private includeFilter(item, filters) {
    return filters.some(filter => String(item).toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  }

  closeCreateModal() {
    this.criaProtheusObject.close();
  }

  closeRemoveModal() {
    this.removeProtheusObject.close();
  }

  getReleaseName(releaseId){
    let releaseIndex;
    let releaseName;
    releaseIndex = this.releases.find(obj => obj.value === releaseId);
    releaseIndex = this.releases.indexOf(releaseIndex);

    if ( releaseIndex > -1 ) {
      releaseName = this.releases[releaseIndex].label;
    } else {
      releaseName = 'NOT FOUND';
    }
    return releaseName;
  }

  getReleaseID(releaseName){
    let releaseIndex;
    let releaseId;
    releaseIndex = this.releases.find(obj => obj.label === releaseName);
    releaseIndex = this.releases.indexOf(releaseIndex);
    if ( releaseIndex > -1 ) {
      releaseId = this.releases[releaseIndex].value;
    } else {
      releaseId = 'NOT FOUND';
    }
    return releaseId;
  }

  getLocName(localizacaoId){
    let locIndex;
    let locName;
    locIndex = this.localizacoesFull.find(obj => obj.value === localizacaoId);
    locIndex = this.localizacoesFull.indexOf(locIndex);

    if ( locIndex > -1 ) {
      locName = this.localizacoesFull[locIndex].label;
    } else {
      locName = 'NOT FOUND';
    }
    return locName;
  }

  getLocID(localizacaoName){
    let locIndex;
    let locID;
    locIndex = this.localizacoesFull.find(obj => obj.name === localizacaoName);
    locIndex = this.localizacoesFull.indexOf(locIndex);

    if ( locIndex > -1 ) {
      locID = this.localizacoesFull[locIndex].value;
    } else {
      locID = 'NOT FOUND';
    }
    return locID;
  }

  onChangeFields(changedValue: PoDynamicFormFieldChanged) {

  }

  onLoadFields(value: any) {
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
