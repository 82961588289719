import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { AdminPanelComponent } from './menu/admin/admin-panel/admin-panel.component';
import { JamesAdminComponent } from './menu/admin/james-admin/james-admin.component';
import { ProtheusAdminComponent } from './menu/admin/protheus-admin/protheus-admin.component';
import { DatabaseComponent } from './menu/database/database.component';
import { HomeComponent } from './menu/home/home.component';
import { JamesbComponent } from './menu/jamesb/jamesb.component';
import { MenuComponent } from './menu/menu.component';
import { ProtheusComponent } from './menu/protheus/protheus.component';
import { QueryAnalyzerComponent } from './menu/query-analyzer/query-analyzer.component'
import { SupportComponent } from './menu/support/support.component';
import { TotvsNewsPreviewComponent } from './menu/totvs-news-preview/totvs-news-preview.component';
import { QueryManagerComponent } from './menu/query-manager/query-manager.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'menu', component: MenuComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], canLoad: [AuthGuard],
        children: [
          { 
            path: 'home', 
            component: HomeComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'database', 
            component: DatabaseComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'protheus', 
            component: ProtheusComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          }
          ,
          { 
            path: 'query-analyzer', 
            component: QueryAnalyzerComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'test-manager', 
            component: QueryManagerComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'jamesbond', 
            component: JamesbComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          {
            path: 'totvs-news-preview',
            component: TotvsNewsPreviewComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'support', 
            component: SupportComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'adminpanel', 
            component: AdminPanelComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'protheusAdmin', 
            component: ProtheusAdminComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          },
          { 
            path: 'jamesbAdmin', 
            component: JamesAdminComponent,
            canActivateChild: [AuthGuard], canLoad: [AuthGuard]
          }
        ] },
  // { path: 'loginError', component: LoginErrorComponent },
  { path: '', pathMatch: 'full', canActivateChild: [AuthGuard], canLoad: [AuthGuard], redirectTo: '/menu/home',}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
