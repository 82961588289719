import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProtheusComponent } from './protheus.component';
import { PoModule } from '@po-ui/ng-components';
import { ProtheusService } from './protheus.service';
import { MainService } from 'src/app/app.service';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    ProtheusComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    AppRoutingModule,
    PoModule,
    PoTemplatesModule,
    BrowserAnimationsModule 
  ],
  providers: [
    MainService,
    ProtheusService
  ]
})
export class ProtheusModule { }
