<po-page-list 
  p-title="JamesBond - Regras"> 
  
  <div class="po-row">

    <po-button
      class="po-lg-3 po-mt-3"
      (p-click)="criajamesrule.open()"
      p-label="Cadastrar regra" >
    </po-button>

  </div>

  <po-divider></po-divider>
  
  <po-container class="po-lg-12">
    <po-table
          p-height="400"
          p-actions-right="true"
          [p-columns]="jamesBColumns"
          [p-items]="jamesBItem"
          [p-striped]="true"
          [p-actions]="ruleActions"
          p-sort="true">
    </po-table>
  </po-container>

  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>
    
</po-page-list>


<po-modal #criajamesrule
    p-title="Nova Regra"
    [p-primary-action]="confirm"
    [p-secondary-action]="close">

    <po-dynamic-form
      #dynamicForm
      p-auto-focus="rule"
      [p-fields]="rule_fields"
      [p-load]="onLoadFields.bind(this)"
      [p-validate]="this.onChangeFields.bind(this)"
      [p-value]="newrule"
    >
    </po-dynamic-form>


</po-modal>

<po-modal #AppDB
    p-title="Nova Regra"
    [p-primary-action]="addComponent"
    [p-secondary-action]="closeComponent">

    <div class="po-row">
      <po-select
        class="po-lg-6"
        name="component"
        p-label="Componente"
        [p-options]="componentList"
        [(ngModel)]="__componente">
      </po-select>
    </div>

    <div class="po-row">
      <po-input
        class="po-md-5"
        name="appBuild"
        p-clean
        p-label="Build"
        [(ngModel)]="__build">
      </po-input>
    </div>
  
    <div class="po-row">
      <po-input
        class="po-md-5"
        name="appVersion"
        p-clean
        p-label="Nome"
        [(ngModel)]="__description">
      </po-input>
    </div>
  
    <div class="po-row">
      <po-input
        class="po-md-5"
        name="appVersion"
        p-clean
        p-label="Versão"
        [(ngModel)]="__version">
      </po-input>
    </div>
    
    <div class="po-row">
      <po-switch
          p-label="Ativar?"
          name="appAvailable" 
          class="po-lg-6"
          [(ngModel)]="__valid"
          p-label-off="Não"
          p-label-on="Sim">
      </po-switch>
    </div>

</po-modal>

<po-modal #EditRule
    p-title="Editar"
    [p-primary-action]="editRule"
    [p-secondary-action]="closeRuleEdit">

    <po-dynamic-form
      #dynamicForm
      p-auto-focus="rule"
      [p-fields]="rule_fields"
      [p-load]="onLoadFields.bind(this)"
      [p-validate]="this.onChangeFields.bind(this)"
      [(p-value)]="newrule"
    >
    </po-dynamic-form>

</po-modal>

