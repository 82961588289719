export class QueryDocument {
    constructor(
    
    ) {
        
    }
    public _id: string;
    public squad: string;
    public source: string;
    public func: string;
    public line: string;
    public query: string;
    public planning: string;
    public modulo: string;
    public area: string | null;
    public user: string | null

}