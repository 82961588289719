import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError, pipe } from 'rxjs';

import { MainService } from '../../../app.service';

@Injectable({ providedIn: 'root' })

export class JamesAdminService {

    constructor( private _http: HttpClient, private mainService: MainService) {
    }

    /*
    GET /jb/whitelist
    GET /jb/whitelist/:id
    POST /jb/whitelist
    PUT /jb/whitelist
    DELETE /jb/whitelist
    ah sim o put e o delete tem /:id no final
    GET /jb/config
    GET /jb/config/:id
    POST /jb/config
    PUT /jb/config/:id
    DELETE /jb/config/:id
    tem duas rotas de GET nos configs, mas é pro Protheus
    */

// - JamesAdmin -------------------------------------------------------------------------------------------
getRules(): Observable<any> {
    return this._http.get(this.mainService.url + '/jb/config', this.mainService.httpOptions )
                .pipe(
                        retry(3),
                        map(rules => {
                            return rules;
                        }),
                        catchError(this.mainService.handleError));
}

postRule (checkEnv, 
            logError, 
            checkUser, 
            showInformer, 
            alwaysShow, 
            checkBuildVersion, 
            maxOldVersion, 
            informerKillUser, 
            showWebMessage, 
            webMessage, 
            queryLog, 
            isCanary, 
            lastPTM, 
            checkIni,
            isActive): Observable<any> {
    return this._http
        .post(this.mainService.url + '/jb/config',
                JSON.stringify({checkEnv, 
                    logError, 
                    checkUser, 
                    showInformer, 
                    alwaysShow, 
                    checkBuildVersion, 
                    maxOldVersion, 
                    informerKillUser, 
                    showWebMessage, 
                    webMessage, 
                    queryLog, 
                    isCanary, 
                    lastPTM, 
                    checkIni,
                    isActive}), this.mainService.httpOptions)
        .pipe(
            map(res => {
                return res;
        }),
            catchError(this.mainService.handleError)
        );
}

editRule (id,
        checkEnv, 
        logError, 
        checkUser, 
        showInformer, 
        alwaysShow, 
        checkBuildVersion, 
        maxOldVersion, 
        informerKillUser, 
        showWebMessage, 
        webMessage, 
        queryLog, 
        isCanary, 
        lastPTM,
        checkIni,
        isActive): Observable<any> {
    return this._http
                .put(this.mainService.url + '/jb/config/' + id,
                        JSON.stringify({
                            checkEnv, 
                            logError, 
                            checkUser, 
                            showInformer, 
                            alwaysShow, 
                            checkBuildVersion, 
                            maxOldVersion, 
                            informerKillUser, 
                            showWebMessage, 
                            webMessage, 
                            queryLog, 
                            isCanary, 
                            lastPTM, 
                            isActive}), this.mainService.httpOptions)
                .pipe(
                    map(res => {
                        return res;
                }),
                    catchError(this.mainService.handleError)
                );
    }

modifyRulesComponent (id, artefato, objeto): Observable<any> {
    this.mainService.updateHeaders();
    let appserver;
    let dbaccess;

    if ( artefato == 'appserver' ) {
        appserver = objeto;
    } else if ( artefato == 'dbaccess' ) {
        dbaccess = objeto;
    } else if ( artefato == 'smartclient' ) {

    }
    return this._http.put(this.mainService.url + '/jb/config/' + id, JSON.stringify({ appserver, dbaccess }), this.mainService.httpOptions)
    .pipe(
        map( res => {
            return res;
        }),
            catchError(this.mainService.handleError)
    );
}

deleteRule(email): Observable<any> {
    return this._http.delete(this.mainService.url + '/totvsId/' + email, this.mainService.httpOptions)
    .pipe(
        catchError(this.mainService.handleError)
    );
}
// ------------------------------------------------------------------------------------------- SPOTS -

}