<po-page-list 
  p-title="Protheus - Admin"
  [p-actions]="actions"
  [p-filter]="filterSettings">

  <div class="po-row">
      <po-select
      class="po-md-6"
      name="objetosProtheus"
      [(ngModel)]="objetosPSelected"
      p-label="Objetos - Protheus"
      p-required
      [p-options]="objetosP"
      (p-change)="onChangeobjetosP($event)"
      >
      </po-select>

  </div>

  <div class="po-row">
    <po-container class="po-lg-12">

      <po-table
        p-container
        p-sort="true"
        [p-columns]="objetosPColumns"
        [p-items]="protheusFiltered"
        [p-striped]="true"
        [p-actions]="eventsObject"
        p-actions-right="false">

      </po-table>

  </po-container>
  </div>
  
    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>
    
</po-page-list>

<po-modal #criaprotheusObject
    [p-title]="titulo"
    [p-primary-action]="confirm"
    [p-secondary-action]="close">

    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>

    <po-select *ngIf="releaseVisible"
      class="po-md-6"
      name="releases"
      [(ngModel)]="_release"
      p-label="Releases"
      p-required
      (p-change)="onChangeRelease($event)"
      [p-options]="releases"
      >
    </po-select>

    <po-select *ngIf="locRequired"
      class="po-md-6"
      name="localizacoes"
      [(ngModel)]="_localizacao"
      p-label="Localização"
      p-required
      [p-options]="localizacoes"
      >
    </po-select>

    <po-dynamic-form
      #dynamicForm
      p-auto-focus="rule"
      [p-fields]="rule_fields"
      [p-load]="onLoadFields.bind(this)"
      [p-validate]="this.onChangeFields.bind(this)"
      [p-value]="newrule"
    >
    </po-dynamic-form>

</po-modal>

<po-modal #editProtheusObject
    [p-title]="titulo"
    [p-primary-action]="edit"
    [p-secondary-action]="closeEdit">

    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>

    <po-select *ngIf="releaseVisible"
      class="po-md-6"
      name="releases"
      [(ngModel)]="_release"
      p-label="Releases"
      p-required
      (p-change)="onChangeRelease($event)"
      [p-options]="releases"
      >
    </po-select>

    <po-select *ngIf="locRequired"
      class="po-md-6"
      name="localizacoes"
      [(ngModel)]="_localizacao"
      p-label="Localização"
      p-required
      [p-options]="localizacoes"
      >
    </po-select>

    <po-dynamic-form
      #dynamicForm
      p-auto-focus="rule"
      [p-fields]="rule_fields"
      [p-load]="onLoadFields.bind(this)"
      [p-validate]="this.onChangeFields.bind(this)"
      [p-value]="editrule"
    >
    </po-dynamic-form>

</po-modal>

<po-modal #removeProtheusObject
    [p-title]="titulo"
    [p-primary-action]="delete"
    [p-secondary-action]="closeDelete">

    <po-info 
      p-label="Deseja excluir o objeto abaixo?" 
      [p-value]="_objectName"> 
    </po-info>


</po-modal>