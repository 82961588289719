export class Recipes {
    public id: string;
    public name: string;
    public rundeckId: string;
    public taskType: string;
    public spotType: string;
    public softwareId: string;
    public version: string;
    public preset: string;
    public area: string;
    public presetVersion: string;
    public label: string;
    public value: string;
    public kind: string;
    public language: string;
    public locale: string;
    public release: string;
    public softwareType: string;
    public url: string;
    public createdAt: string;
    public updatedAt: string;
    public published: boolean;
}
