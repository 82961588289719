export class Token {
    public id: string;
    public username: string;
    public password: string;
    public token: string;
    public compileToken: string;
    public compileExpiration: string;
    public role: string;
    public area: string;
    public areaID: string;
    public restricted: string;
    public createdAt: string;
}
