import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, retry } from 'rxjs';
import { MainService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class HostsService {

  constructor( private _http: HttpClient, private mainService: MainService) {
  }

  // - HOSTS -------------------------------------------------------------------------------------------
  getHosts(): Observable<any> {
      return this._http.get('/api/v1/hosts', this.mainService.httpOptions )
                  .pipe(
                          retry(3),
                          map(hosts => {
                              return hosts;
                          }),
                          catchError(this.mainService.handleError));
  }

  getHostName(id: string, hosts: Array<any>) {
      let hostIndex;
      let hostName;
      hostIndex = hosts.find(obj => obj.id === id);
      hostIndex = hosts.indexOf(hostIndex);
      if ( hostIndex > -1 ) {
        hostName = hosts[hostIndex].name;
      } else {
        hostName = 'NOT FOUND';
      }

      return hostName;
  }
  // ------------------------------------------------------------------------------------------- HOSTS -

}
