export class Bases {
    public id: string;
    public release: string;
    public localizacao: string;
    public tipo: string;
    public dbAlias: string;
    public recipeId: string;
    public kind: string;
    public language: string;
    public locale: string;
    public softwareType: string;
    public url: string;
    public createdAt: string;
    public updatedAt: string;
    public published: boolean;
}