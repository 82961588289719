import { Component } from '@angular/core';

@Component({
  selector: 'app-query-analyzer',
  templateUrl: './query-analyzer.component.html',
  styleUrls: ['./query-analyzer.component.css']
})
export class QueryAnalyzerComponent {

}
