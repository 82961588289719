import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { PoCodeEditorModule } from '@po-ui/ng-code-editor';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { QueryManagerService } from './query-manager.service';
import { MainService } from 'src/app/app.service';
import { QueryManagerComponent } from './query-manager.component';



@NgModule({
  declarations: [
    QueryManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    PoModule,
    PoTemplatesModule,
    PoCodeEditorModule,
    NotificationItemComponent
  ],
  providers: [
    MainService,
    QueryManagerService
  ]
})
export class QueryManagerModule { }
