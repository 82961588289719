import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError, pipe } from 'rxjs';

import { MainService } from '../../../app.service';

@Injectable({ providedIn: 'root' })
export class ProtheusAdminService {

  constructor( private _http: HttpClient, private mainService: MainService) { }


getBinarios(): Observable<any> {
    return this._http.get(this.mainService.url_v2 + '/binaries', this.mainService.httpOptions )
                .pipe(
                        retry(3),
                        map(binarios => {
                            return binarios;
                        }),
                        catchError(this.mainService.handleError));
}

getLocalizacoes(): Observable<any> {
  return this._http.get(this.mainService.url_v2 + '/locales', this.mainService.httpOptions )
              .pipe(
                      retry(3),
                      map(localizacoes => {
                          return localizacoes;
                      }),
                      catchError(this.mainService.handleError));
}

getLocalizacoesbyRelease(release): Observable<any> {
    return this._http.get(this.mainService.url_v2 + '/locales/?release=' + release, this.mainService.httpOptions )
                .pipe(
                        retry(3),
                        map(localizacoes => {
                            return localizacoes;
                        }),
                        catchError(this.mainService.handleError));
    }

getReleases(): Observable<any> {
  return this._http.get(this.mainService.url_v2 + '/releases', this.mainService.httpOptions )
              .pipe(
                      retry(3),
                      map(releases => {
                          return releases;
                      }),
                      catchError(this.mainService.handleError));
}

getRPOs(): Observable<any> {
  return this._http.get(this.mainService.url_v2 + '/rpo', this.mainService.httpOptions )
              .pipe(
                      retry(3),
                      map(rpo => {
                          return rpo;
                      }),
                      catchError(this.mainService.handleError));
}

getBases(): Observable<any> {
    return this._http.get(this.mainService.url_v2 + '/bases', this.mainService.httpOptions )
                .pipe(
                        retry(3),
                        map(bases => {
                            return bases;
                        }),
                        catchError(this.mainService.handleError));
  }

postBinario (release, appserver_version, appserver_build, appserver_image, dbaccess_version, dbaccess_image, published): Observable<any> {
    return this._http
        .post(this.mainService.url_v2 + '/binaries',
                JSON.stringify({release, appserver_version, appserver_build, appserver_image, dbaccess_version, dbaccess_image, published}), this.mainService.httpOptions)
        .pipe(
            map(res => {
                return res;
        }),
            catchError(this.mainService.handleError)
        );
}

postDbAccess (release, dbaccess_version, dbaccess_image, published): Observable<any> {
  return this._http
      .post(this.mainService.url_v2 + '/dbaccess',
              JSON.stringify({release, dbaccess_version, dbaccess_image, published}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

postLocalizacoes (release, locale, link_menu, link_help, link_dic, congelada, published, regional_language): Observable<any> {
  return this._http
      .post(this.mainService.url_v2 + '/locales',
              JSON.stringify({release, locale, regional_language, link_menu, link_help, link_dic, congelada, published}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

postReleases (name, idiomas, valid): Observable<any> {
  return this._http
      .post(this.mainService.url_v2 + '/releases',
              JSON.stringify({name, idiomas, valid}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

postRPOs (release, version, idioma, localizacao, link_rpo, published): Observable<any> {
  return this._http
      .post(this.mainService.url_v2 + '/rpo',
              JSON.stringify({release, version, idioma, localizacao, link_rpo, published}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

postBases (release, localizacao, tipo, dbAlias, recipeId, published): Observable<any> {
    return this._http
        .post(this.mainService.url_v2 + '/bases',
                JSON.stringify({release, localizacao, tipo, dbAlias, recipeId, published}), this.mainService.httpOptions)
        .pipe(
            map(res => {
                return res;
        }),
            catchError(this.mainService.handleError)
        );
  }

putBinario (id, release, appserver_version, appserver_build, appserver_image, dbaccess_version, dbaccess_image, published): Observable<any> {
    return this._http
        .put(this.mainService.url_v2 + '/binaries/'+ id,
                JSON.stringify({release, appserver_version, appserver_build, appserver_image, dbaccess_version, dbaccess_image, published}), this.mainService.httpOptions)
        .pipe(
            map(res => {
                return res;
        }),
            catchError(this.mainService.handleError)
        );
}

putDbAccess (id, release, dbaccess_version, dbaccess_image, published): Observable<any> {
  return this._http
      .put(this.mainService.url_v2 + '/dbaccess/'+ id,
              JSON.stringify({release, dbaccess_version, dbaccess_image, published}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

putLocalizacoes (id, release, locale, link_menu, link_help, link_dic, congelada, published, regional_language): Observable<any> {
  return this._http
      .put(this.mainService.url_v2 + '/locales/'+ id,
              JSON.stringify({release, locale, link_menu, regional_language, link_help, link_dic, congelada, published}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

putReleases (id, name, idiomas, valid): Observable<any> {
  return this._http
      .put(this.mainService.url_v2 + '/releases/'+ id,
              JSON.stringify({name, idiomas, valid}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

putRPOs (id, release, version, idioma, localizacao, link_rpo, published): Observable<any> {
  return this._http
      .put(this.mainService.url_v2 + '/rpo/'+ id,
              JSON.stringify({release, version, idioma, localizacao, link_rpo, published}), this.mainService.httpOptions)
      .pipe(
          map(res => {
              return res;
      }),
          catchError(this.mainService.handleError)
      );
}

putBases (id, release, localizacao, tipo, dbAlias, recipeId): Observable<any> {
    return this._http
        .put(this.mainService.url_v2 + '/bases/'+ id,
                JSON.stringify({release, localizacao, tipo, dbAlias, recipeId}), this.mainService.httpOptions)
        .pipe(
            map(res => {
                return res;
        }),
            catchError(this.mainService.handleError)
        );
  }

// modifySpots (id, type, softwareId, availability, area): Observable<any> {
//     this.mainService.updateHeaders();
//     return this._http.put(this.mainService.url + '/spots/' + id, JSON.stringify({ type, softwareId, availability, area}), this.mainService.httpOptions)
//         .pipe(
//             map( res => {
//                 return res;
//             }),
//                 catchError(this.mainService.handleError)
//         );
// }

deleteBinario (id): Observable<any> {
    return this._http.delete(this.mainService.url_v2 + '/binaries/' + id, this.mainService.httpOptions)
    .pipe(
        catchError(this.mainService.handleError)
    );
}

deleteLocalizacao (id): Observable<any> {
    return this._http.delete(this.mainService.url_v2 + '/locales/' + id, this.mainService.httpOptions)
    .pipe(
        catchError(this.mainService.handleError)
    );
}

deleteRelease (id): Observable<any> {
    return this._http.delete(this.mainService.url_v2 + '/releases/' + id, this.mainService.httpOptions)
    .pipe(
        catchError(this.mainService.handleError)
    );
}

deleteRPO (id): Observable<any> {
    return this._http.delete(this.mainService.url_v2 + '/rpo/' + id, this.mainService.httpOptions)
    .pipe(
        catchError(this.mainService.handleError)
    );
}

deleteBases (id): Observable<any> {
    return this._http.delete(this.mainService.url_v2 + '/bases/' + id, this.mainService.httpOptions)
    .pipe(
        catchError(this.mainService.handleError)
    );
}

}
