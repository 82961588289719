import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { JamesAdminService } from './james-admin.service';
import { MainService } from 'src/app/app.service';
import { JamesBRules } from 'src/app/classes/JamesBRules';
import { PoDynamicFormField, PoDynamicFormFieldChanged, PoModalAction, PoModalComponent, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { Subject, takeUntil } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-james-admin',
  templateUrl: './james-admin.component.html',
  styleUrls: ['./james-admin.component.css']
})
export class JamesAdminComponent implements OnInit {

  @ViewChild('criajamesrule', {static: false}) criaJamesRuleModal: PoModalComponent;
  @ViewChild('AppDB', {static: false}) addAppDBModalComponent: PoModalComponent;
  @ViewChild('EditRule', {static: false}) editJamesRuleModal: PoModalComponent;
  

  constructor(public mainService: MainService, 
              public jamesRulesService: JamesAdminService,
              public cd: ChangeDetectorRef,
              private readonly tracker: MatomoTracker,) { }

  unsubscribe$ = new Subject<void>();            

  jamesBColumns: Array<PoTableColumn>  = [
    { property: 'checkEnv', label: 'Check Environment', type: 'boolean'},
    { property: 'logError', label: 'Log Error', type: 'boolean'},
    { property: 'checkUser', label: 'Check User', type: 'boolean'},
    { property: 'showInformer', label: 'Show Informer', type: 'boolean'},
    { property: 'alwaysShow', label: 'Always Show', type: 'boolean' },
    { property: 'checkBuildVersion', label: 'Check Build Version', type: 'boolean' },
    { property: 'informerKillUser', label: 'Kill User', type: 'boolean' },
    { property: 'maxOldVersion', label: 'Max Old Version', type: 'string' },
    { property: 'showWebMessage', label: 'Show Web Message', type: 'boolean' },
    { property: 'webMessage', label: 'Message', type: 'string' },
    { property: 'queryLog', label: 'Query Log', type: 'boolean' },
    { property: 'isCanary', label: 'Canary', type: 'boolean' },
    { property: 'LastPTM', label: 'Last PTM', type: 'boolean' },
    { property: 'checkIni', label: 'checkIni', type: 'boolean' },
    { property: 'valid', label: 'Active?', type: 'boolean' },
    { property: 'details', label: 'Componentes', type: 'detail', detail: {
      columns: [
        { property: 'type', label: 'Objeto' },
        { property: 'build', label: 'Build' },
        { property: 'description', label: 'Descrição' },
        { property: 'valid', label: 'Valid' },
        { property: 'version', label: 'Versão' },
        { property: '_id', label: 'ID' }
      ],
      typeHeader: 'top'}
    }
  ];
  jamesBItem: Array<any> = [];
  jamesBItemDel: Array<any> = [];

  rule_fields: Array<PoDynamicFormField> = [
    { property: 'checkEnv', label: 'Check Environment', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'logError', label: 'Log Error', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'checkUser', label: 'Check User', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'showInformer', label: 'Show Informer', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'alwaysShow', label: 'Always Show', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'checkBuildVersion', label: 'Check Build Version', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'informerKillUser', label: 'Kill User', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'maxOldVersion', label: 'Max Old Version', type: 'string', gridColumns: 6, gridSmColumns: 12 },
    { property: 'showWebMessage', label: 'Show Web Message', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'webMessage', label: 'Message', type: 'string', gridColumns: 6, gridSmColumns: 12 },
    { property: 'queryLog', label: 'Query Log', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'isCanary', label: 'Canary', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'LastPTM', label: 'Last PTM', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'checkIni', label: 'checkIni', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
    { property: 'valid', label: 'Active?', type: 'boolean', gridColumns: 6, gridSmColumns: 12 },
  ];
  newrule: Array<any> = [];

  _id = '';
  _checkEnv = false;
  _logError = false;
  _checkUser = false;
  _showInformer = false;
  _alwaysShow = false;
  _checkBuildVersion = false;
  _maxOldVersion = '';
  _informerKillUser = false;
  _showWebMessage = false;
  _webMessage = '';
  _queryLog = false;
  _isCanary = false;
  _LastPTM = false;
  _appserver_build = '';
  _appserver_version = '';
  _appserver_note = '';
  _appserver_valid = '';
  _dbaccess_build_api = '';
  _checkIni = false;
  _valid = false;

  __componente = '';
  __build = '';
  __description = '';
  __version = '';
  __valid = false; 

  componentList = [
    { label: 'Appserver', value: 'appserver' },
    { label: 'DbAccess', value: 'dbaccess' }
  ];

  ruleActions: Array<PoTableAction> = [
    { action: this.addAppDBOpen.bind(this), label: 'Adicionar produto' },
    { action: this.editRuleOpen.bind(this), label: 'Editar Regra' }
  ];

  confirm: PoModalAction = {
    action: () => {
      this.criarJamesb();
    },
    label: 'Create!'
  }

  addComponent: PoModalAction = {
    action: () => {
      this.addAppDB();
    },
    label: 'Create!'
  }

  editRule: PoModalAction = {
    action: () => {
      this.editRuleSave();
    },
    label: 'Create!'
  }

  close: PoModalAction = {
    action: () => {
      this.closeCreateModal();
    },
    label: 'Close',
    danger: true
  };

  closeComponent: PoModalAction = {
    action: () => {
      this.closeAddModal();
    },
    label: 'Cancel',
    danger: true
  };

  closeRuleEdit: PoModalAction = {
    action: () => {
      this.closeEditModal();
    },
    label: 'Cancel',
    danger: true
  };

  loading = false;

  ngOnInit() {
    this.tracker.trackPageView('JamesB_Admin');
    this.getJamesB();
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  getJamesB() {
    this.jamesBItem = [ ];
    this.jamesRulesService.getRules()
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          retorno.forEach(element => {
            const jamesRuleTemp = new JamesBRules();
            jamesRuleTemp.id = element.id;
            jamesRuleTemp.checkEnv = element.checkEnv;
            jamesRuleTemp.logError = element.logError;
            jamesRuleTemp.checkUser = element.checkUser;
            jamesRuleTemp.showInformer = element.showInformer;
            jamesRuleTemp.alwaysShow = element.alwaysShow;
            jamesRuleTemp.checkBuildVersion = element.checkBuildVersion;
            jamesRuleTemp.maxOldVersion = element.maxOldVersion;
            jamesRuleTemp.informerKillUser = element.informerKillUser;
            jamesRuleTemp.showWebMessage = element.showWebMessage;
            jamesRuleTemp.webMessage = element.webMessage;
            jamesRuleTemp.queryLog = element.queryLog;
            jamesRuleTemp.isCanary = element.isCanary;
            jamesRuleTemp.LastPTM = element.lastPTM;
            jamesRuleTemp.checkIni = element.checkIni;
            jamesRuleTemp.valid = element.isActive;
            let details: Array<any> = [];
            let len = element.appserver.length; 
            let len2 = element.dbaccess.length;
            for (let i = 0; i < len; i++) {
              details.push({ 
                type: 'AppServer', 
                build: element.appserver[i].build, 
                description: element.appserver[i].description, 
                valid: element.appserver[i].valid, 
                version: element.appserver[i].version, 
                _id: element.appserver[i]._id});
              }
            
            for (let i = 0; i < len2; i++) {
              details.push({ 
                type: 'DbAccess', 
                build: element.dbaccess[i].build, 
                description: element.dbaccess[i].description, 
                valid: element.dbaccess[i].valid, 
                version: element.dbaccess[i].version, 
                _id: element.dbaccess[i]._id});
              }         

            jamesRuleTemp.details = details;

            this.jamesBItem = [...this.jamesBItem, jamesRuleTemp];
            
          });               
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      }); 
  }

  criarJamesb() {
      this.loading = true;

        this._checkEnv = this.newrule['checkEnv'] || false;
        this._logError = this.newrule['logError'] || false;
        this._checkUser = this.newrule['checkUser'] || false;
        this._showInformer = this.newrule['showInformer'] || false;
        this._alwaysShow = this.newrule['alwaysShow'] || false;
        this._checkBuildVersion = this.newrule['checkBuildVersion'] || false;
        this._maxOldVersion = this.newrule['maxOldVersion'] || '';
        this._informerKillUser = this.newrule['informerKillUser'] || false;
        this._showWebMessage = this.newrule['showWebMessage'] || false;
        this._webMessage = this.newrule['webMessage'] || '';
        this._queryLog = this.newrule['queryLog'] || false;
        this._isCanary = this.newrule['isCanary'] || false;
        this._LastPTM = this.newrule['LastPTM'] || false;
        this._checkIni = this.newrule['checkIni'] || false;
        this._appserver_build = this.newrule['appserver_build'] || '';
        this._appserver_version = this.newrule['appserver_version'] || '';
        this._appserver_note = this.newrule['appserver_note'] || '';
        this._appserver_valid = this.newrule['appserver_valid'] || '';
        this._dbaccess_build_api = this.newrule['dbaccess_build_api']  || '';
        this._valid = this.newrule['valid'] || false;

      this.jamesRulesService.postRule(
                                  this._checkEnv, 
                                  this._logError, 
                                  this._checkUser, 
                                  this._showInformer, 
                                  this._alwaysShow, 
                                  this._checkBuildVersion, 
                                  this._maxOldVersion, 
                                  this._informerKillUser, 
                                  this._showWebMessage, 
                                  this._webMessage, 
                                  this._queryLog, 
                                  this._isCanary, 
                                  this._LastPTM, 
                                  this._checkIni,
                                  this._valid)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: ((retorno) => {
                this.getJamesB();
            this.loading = false;
                          
              }),
              error: ((msgError) => {
                this.mainService.handleError(msgError);
                this.loading = false;
              })
            });

      this.closeCreateModal();
  }

  addAppDBOpen(ruleSelected) {
    this._id = ruleSelected['id'];
    
    this.addAppDBModalComponent.open();

  }
  addAppDB() {  
    this.loading = true;
      let appserver: Array<any> = [];
      appserver.push({
        build: this.__build,
        version: this.__version,
        description: this.__description,
        valid: this.__valid
      })
      
    this.jamesRulesService.modifyRulesComponent( this._id, this.__componente, appserver )
        .subscribe(res => {
          this.getJamesB();
          this.loading = false;
        });

    this.loading = false;
    this.closeAddModal();
    this.getJamesB();
}

  editRuleOpen(ruleSelected) {
    this._id = ruleSelected['id'];

    this.newrule['checkEnv'] = ruleSelected['checkEnv'];
    this.newrule['logError'] = ruleSelected['logError'];
    this.newrule['checkUser'] = ruleSelected['checkUser'];
    this.newrule['showInformer'] = ruleSelected['showInformer'];
    this.newrule['alwaysShow'] = ruleSelected['alwaysShow'];
    this.newrule['checkBuildVersion'] = ruleSelected['checkBuildVersion'];
    this.newrule['maxOldVersion'] = ruleSelected['maxOldVersion'];
    this.newrule['informerKillUser'] = ruleSelected['informerKillUser'];
    this.newrule['showWebMessage'] = ruleSelected['showWebMessage'];
    this.newrule['webMessage'] = ruleSelected['webMessage'];
    this.newrule['queryLog'] = ruleSelected['queryLog'];
    this.newrule['isCanary'] = ruleSelected['isCanary'];
    this.newrule['LastPTM'] = ruleSelected['LastPTM'];
    this.newrule['checkIni'] = ruleSelected['checkIni'];
    this.newrule['valid'] = ruleSelected['valid'];
    

    this.editJamesRuleModal.open();
  }

  editRuleSave() {

    this._checkEnv = this.newrule['checkEnv'];
    this._logError = this.newrule['logError'];
    this._checkUser = this.newrule['checkUser'];
    this._showInformer = this.newrule['showInformer'];
    this._alwaysShow = this.newrule['alwaysShow'];
    this._checkBuildVersion = this.newrule['checkBuildVersion'];
    this._maxOldVersion = this.newrule['maxOldVersion'];
    this._informerKillUser = this.newrule['informerKillUser'];
    this._showWebMessage = this.newrule['showWebMessage'];
    this._webMessage = this.newrule['webMessage'];
    this._queryLog = this.newrule['queryLog'];
    this._isCanary = this.newrule['isCanary'];
    this._LastPTM = this.newrule['LastPTM'];
    this._checkIni = this.newrule['checkIni'];
    this._appserver_build = this.newrule['appserver_build'];
    this._appserver_version = this.newrule['appserver_version'];
    this._appserver_note = this.newrule['appserver_note'];
    this._appserver_valid = this.newrule['appserver_valid'];
    this._dbaccess_build_api = this.newrule['dbaccess_build_api'];
    this._valid = this.newrule['valid'];
    this.jamesRulesService.editRule(
                              this._id,
                              this._checkEnv, 
                              this._logError, 
                              this._checkUser, 
                              this._showInformer, 
                              this._alwaysShow, 
                              this._checkBuildVersion, 
                              this._maxOldVersion, 
                              this._informerKillUser, 
                              this._showWebMessage, 
                              this._webMessage, 
                              this._queryLog, 
                              this._isCanary, 
                              this._LastPTM, 
                              this._checkIni,
                              this._valid)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe({
                      next: ((retorno) => {
                        this.getJamesB();
                    this.loading = false;
                                  
                      }),
                      error: ((msgError) => {
                        this.mainService.handleError(msgError);
                        this.loading = false;
                      })
                    });

        this.closeEditModal();

  }

  closeEditModal() {
    this.editJamesRuleModal.close();
  }

  closeCreateModal() {
    this.criaJamesRuleModal.close();
  }

  closeAddModal() {
    this.addAppDBModalComponent.close();
  }

  onChangeFields(changedValue: PoDynamicFormFieldChanged) {
    
  }

  onLoadFields(value: any) {

    return { checkEnv: true }
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }



}
