import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { PoLoadingModule, PoDialogService, PoNotificationService } from '@po-ui/ng-components';
import { PoPageLogin, PoPageLoginLiterals } from '@po-ui/ng-templates';
import { Subject, takeUntil } from 'rxjs';

import { MainService } from '../app.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loading: boolean = false;
  unsubscribe$ = new Subject<void>();
  userLogin = '';
  userPass = '';

  customLiterals: PoPageLoginLiterals = {
    loginPlaceholder: 'Insira seu usuário de rede',
    passwordPlaceholder: 'Insira sua senha de rede',
    submitLabel: 'Login'
  };

  constructor(
    private thfDialog: PoDialogService,
    private router: Router,
    public mainservice: MainService,
    public poNotification: PoNotificationService,
    private readonly tracker: MatomoTracker) { }

  ngOnInit() {
    console.log(this.tracker.getCurrentUrl());
    console.log(this.tracker.getMatomoUrl());
    this.tracker.trackPageView('Login');
  }

  checkLogin(formData: PoPageLogin) {
    this.loading = true;

    this.mainservice.userToken.username = formData.login.toLowerCase();
    this.mainservice.userToken.token = '';
    if (this.mainservice.userToken.username.includes("@")) {
      this.loading = false;
      if (this.mainservice.userToken.username.includes("@totvs") || this.mainservice.userToken.username.includes("@sp01")) {
        const email = this.mainservice.userToken.username.split('@',2);
        this.notification(email[0], 'error');
      } else {
        this.mainservice.createLoginAction (formData.login, formData.password, 'teste-login')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
                  next: ((token) => {
                    console.log('login externo')
                    this.poNotification['success'](`Processo iniciado com sucesso!`);
                  }),
                  error: ((msgError) => {
                    this.mainservice.handleError(msgError);
                    this.loading = false;
                  })}
          );
      }

    } else {
      this.mainservice.getToken(this.mainservice.userToken.username, formData.password)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
              next: ((token) => {
                this.mainservice.userToken.token = token;
                this.loading = false;
                this.router.navigate(['menu']);
              }),
              error: ((msgError) => {
                console.log(this.countLimit());
                this.mainservice.handleError(msgError);
                this.loading = false;
              })}
      );

      this.mainservice.getTokenV2(this.mainservice.userToken.username, formData.password)
      .subscribe({
        next: ((token) => {
        }),
        error: ((msgError) => {
          this.mainservice.handleError(msgError);
          this.loading = false;
        })}
      );
    }
    
  }

  countLimit() {
    let limit = this.mainservice.limite_login + 1;
    sessionStorage.setItem('LimiteLogin', limit.toString());
    if (limit === 1) {
      let timestamp = new Date().getTime();
      sessionStorage.setItem('LimiteLogin_timestamp', timestamp.toString());
    }
    return limit
  }

  notification(email: string, notificationType: string) {
    this.poNotification[notificationType](`Para realizar o login não é necessário o @totvs, apenas ${email}`);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
