import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TestQuery } from '../../services/testQuery';
import { QueryService } from '../../services/query.service';
import { PoInfoOrientation, PoMultiselectOption, PoTableColumn, 
  PoTagOrientation, PoModalAction, PoModalComponent } from '@po-ui/ng-components';
import { ChatbotService } from '../chatbot-ai/chatbot.service';
import { NotificationServiceHM } from 'src/app/menu/notification-item/notification-item.service';

@Component({
  selector: 'test-query',
  templateUrl: './test-query.component.html',
  styleUrls: ['./test-query.component.css']
})
export class TestQueryComponent implements OnInit, OnDestroy {
  @ViewChild('chat') chat: ElementRef | undefined;
  @ViewChild('input') input: ElementRef | undefined;
  @ViewChild('botaoEnviar') botaoEnviar: ElementRef | undefined;
  @ViewChild('scrollTarget') scrollTarget: ElementRef;

  orientation: PoInfoOrientation = PoInfoOrientation.Horizontal;

  data$: Observable<TestQuery>;

  data: any;
  dbresult: any;
  aiAnalyzer: any;
  issueUrl: any = 'https://jira.com.br/test-123';
  issueCode: any = 'test-123';
  issueStatus: any = 'open';
  advpl: any;

  objQuery: TestQuery;
  isRunning: Boolean;

  //Modal-TestQuery
  modelDatabase: Array<string>;
  modelTypeExec: Array<string>;

  optDatabase: Array<PoMultiselectOption> = [
    { value: 'ORACLE', label: 'ORACLE' },
    { value: 'MSSQL', label: 'MSSQL' },
    { value: 'POSTGRES', label: 'POSTGRES' }
  ]

  optTypeExec: Array<PoMultiselectOption> = [
    { value: 'ADVPL', label: 'ADVPL' },
    { value: 'DATABASE', label: 'DATABASE' }
  ]

  //btn-TestQuery
  statusTest: String;
  btnTypeTest: String;
  btnLoading: Boolean;

  //Tag-TestQuery
  tagOrientation: PoTagOrientation = PoTagOrientation.Horizontal;
  statusTestType: String;
  statusTestName: String;

  //input-TestQuery
  testQuery: string = '';

  PgPlan: String;
  MssqlPlan: String;
  OraPlan: String;

  columnsDB: Array<PoTableColumn> = this.getColumnsDB();
  columnsAdvpl: Array<PoTableColumn> = this.getColumnsAdvpl();

  items: Array<any>;
  messageAI: String = 'Deseja que a query seja analisada e melhorada com IA?\n \n A query será analisada e o resultado será registrado em um apoio no JIRA.';
  needAIAnalyzer: Boolean = false;

  confirmAIanalisys: PoModalAction = {
    action: () => {
      this.needAIAnalyzer = true;
      this.objQuery.method.smart = true;
      this.callSendQuery()
      this.needAIModal.close();
    },
    label: 'Sim'
  };

  refuseAIanalisys: PoModalAction = {
    action: () => {
      this.needAIAnalyzer = false;
      this.objQuery.method.smart = false;
      this.callSendQuery()
      this.needAIModal.close();
    },
    label: 'Não'
  };

  constructor(
    private service: QueryService, 
    private chatbotService: ChatbotService,
    public notificationServiceHM: NotificationServiceHM,
  ) {
    this.statusTest = 'Testar';
    this.btnTypeTest = 'secondary';
    this.modelDatabase = ['ORACLE', 'MSSQL', 'POSTGRES']
    this.modelTypeExec = ['ADVPL', 'DATABASE']
  }

  ngOnInit() {
    this.btnLoading = false;
  }

  @ViewChild('aiModal', { static: true }) needAIModal: PoModalComponent;

  async btnTest() {
    this.btnLoading = true;

    this.needAIModal.open()

    this.objQuery = {
      query: this.testQuery,
      sgbds: {
        oracle: this.modelDatabase.includes('ORACLE'),
        mssql: this.modelDatabase.includes('MSSQL'),
        postgres: this.modelDatabase.includes('POSTGRES')
      },
      method: {
        advpl: this.modelTypeExec.includes('ADVPL'),
        database: this.modelTypeExec.includes('DATABASE'),
        smart: this.needAIAnalyzer
      },
      user: {
        area: sessionStorage.getItem('Area'),
        username: sessionStorage.getItem('User')
      }
    }

    this.hasAsterisk(this.testQuery);
    this.hasDelete(this.testQuery);
  }

  hasAsterisk(text){
    if (/w*\*w*/i.test(text)){
        this.showNotification('warning', 'Queries com  * não é uma boa prática!')
      }
  }

  hasDelete(text){
    if (/s*D_E_L_E_T_ <>'\*s*|w*D_E_L_E_T_<> '\*s*|s*D_E_L_E_T_<>'\*s*|w*D_E_L_E_T_ <> '\*s*/i.test(text)){
        this.showNotification('error', 'O banco de dados solicita que a query seja reestruturada! \nNão é permitido utilizar D_E_L_E_T_ <> \'*\' devido gerar alto impacto negativo de performance no banco de dados.\nUtilizer  D_E_L_E_T_ = \' \'')
      }
  }

  showNotification(type: string, message: string) {

      switch (type) {
        case 'success': {
          this.notificationServiceHM.showToast(message, 'success')
          break;
        }
        case 'error': {
          this.notificationServiceHM.showToast(message, 'error')
          break;
        }
        case 'warning': {
          this.notificationServiceHM.showToast(message, 'warning')
          break;
        }
        default: {
          this.notificationServiceHM.showToast(message, 'success')
          break;
        }
      }
  }
 

  scrollToBottom(): void {
    if (this.scrollTarget) {
      this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  limparConversa() {
    this.chatbotService.limparhistorico().subscribe(() => {
      if (this.chat?.nativeElement) {
        this.chat.nativeElement.innerHTML = "<p class='chat__bolha chat__bolha--bot'>Olá! Eu sou o assistente virtual da EcoMart ~<br/><br/>Como posso te ajudar?</p>";
      }
    }, error => {
      console.error('Erro ao limpar conversa:', error);
    });
  }

  callSendQuery() {
    this.service.sendQuery(this.objQuery)
    .pipe(
      tap((result) => {
        this.dbresult = result.value[0].dbResults
        this.aiAnalyzer = result.value[0].aiResults
        this.issueCode = result.value[0].issueCode
        this.issueUrl = result.value[0].issueUrl
        this.issueStatus = result.value[0].issueStatus

        if (result.value[0]['issueCode']) {
          this.notificationServiceHM.showToast(`Issue  ${result.value[0]['issueCode']} criada com sucesso!`, 'success')
          this.notificationServiceHM.showToast(`Link: ${result.value[0]['issueUrl'] }`, 'success')
        }
      })
    )
    .subscribe(() => {
      this.btnLoading = false;
      this.insertItemColumns()
    });
  }

  
  isSuccess(status) {
    return status ? 'success' : 'danger'
  }

  valueTag(status) {
    return status ? 'OK' : 'ERROR'
  }

  mntOraTable(element) {
    this.OraPlan = "";
    if (element.isApproved) {
      JSON.parse(element.execplan).forEach(element => {
        this.OraPlan += `${element['PLAN_TABLE_OUTPUT']} \n` || ''
      });
    }
    return this.OraPlan;
  }

  mntPgPlan(element) {
    this.PgPlan = "";
    if (element.isApproved) {
      JSON.parse(element.execplan).forEach(rows => {
        this.PgPlan += `${rows[0]} \n`
      });
    }
    return this.PgPlan;
  }

  mntSqlPlan(element) {
    this.MssqlPlan = "";
    if (element.isApproved) {
      this.MssqlPlan = JSON.stringify(JSON.parse(element.execplan), undefined, 4);
    }
    return this.MssqlPlan;
  }

  insertItemColumns() {
    for (let i = 0; i < this.dbresult.length; i++) {
      this.dbresult[i].status = (this.dbresult[i].isApproved) ? 'true' : 'false';
      (this.dbresult[i].sgbd == 'MSSQL')
        ? this.dbresult[i].plan = this.mntSqlPlan(this.dbresult[i]) :
        (this.dbresult[i].sgbd == 'ORACLE')
          ? this.dbresult[i].plan = this.mntOraTable(this.dbresult[i]) :
          (this.dbresult[i].sgbd == 'POSTGRESQL')
            ? this.dbresult[i].plan = this.mntPgPlan(this.dbresult[i]) : '';
    }

    if (this.advpl) {
      for (let i = 0; i < this.advpl.length; i++) {
        this.advpl[i].status = (this.advpl[i].BRETMSP) ? 'true' : 'false';
      }
    }
  }

  isApprovedAI(row, index: number) {
    return row.isApproved;
  }

  isApprovedSGBD(row, index: number) {
    return row.isApproved;
  }

  isApprovedAdvpl(row, index: number) {
    return row.BRETMSP;
  }

  getColumnsDB(): Array<PoTableColumn> {
    return [
      { property: 'sgbd', label: 'DBMS', width: '8%' },
      { property: 'message', label: 'Message' },
      { property: 'date', label: 'Test date', type: 'dateTime', width: '15%' },
      {
        property: 'status', label: 'Status', type: 'label', width: '8%', labels: [
          { value: 'true', color: 'success', label: 'Approved' },
          { value: 'false', color: 'danger', label: 'Disapproved' }
        ]
      }
    ];
  }

  getColumnsAdvpl(): Array<PoTableColumn> {
    return [
      { property: 'CSGBD', label: 'Parsing to', width: '8%' },
      { property: 'CRETMSP', label: 'Message' },
      { property: 'DDATE', label: 'Test date', type: 'dateTime', width: '15%' },
      {
        property: 'status', label: 'Status', type: 'label', width: '8%', labels: [
          { value: 'true', color: 'success', label: 'Approved' },
          { value: 'false', color: 'danger', label: 'Disapproved' }
        ]
      }
    ];
  }

  ngOnDestroy() {
  }

}
