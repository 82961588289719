import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private apiUrl = environment.API_CHAT;

  constructor(private http: HttpClient) { }

  enviarPergunta(pergunta: string) {
    return this.http.post<any>(this.apiUrl, { msg: pergunta })
    .pipe(
        // tap(console.log)
    );
  }
  limparhistorico() {
    return this.http.post<any>(this.apiUrl, {})
    .pipe(
        // tap(console.log)
    );
  }
}
