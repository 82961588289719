export class Issue {
    constructor(
    
    ) {
        
    }
    public _id: String;
    public squad: String;
    public source: String;
    public func: String;
    public line: String;
    public query: String;
    public advplerrors: String;
    public dbmserrors: String;
    public planning?: String;
    public modulo?: String;
    public area?: String | null;
    public user?: String | null   
    public _smarttest?: Boolean | String | null

}