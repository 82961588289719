<po-page-dynamic-search 
p-title="Pontos de Atenção"
[p-filters]="filters"
[p-literals]="customLiterals"
(p-change-disclaimers)="onChangeDisclaimers($event)"
(p-quick-search)="onQuickSearch($event)"
(p-advanced-search)="onAdvancedSearch($event)"
[p-actions]="actions_primary"
> 

<div class="po-row">
  <div class="po-md-12">
    <po-tabs>
      <po-tab *ngFor="let tab of tabs; let i = index" [p-active]="(i === 0)"
        [p-label]="tab.name" (p-click)="cliclSetting(tab.label)">
        <div>
          <po-accordion>
            <po-accordion-item p-label="Resumo">
              <po-container>
                <p>Quantidade de Queries com ({{tab.name}}) por Squad. </p>
                <po-widget *ngFor="let squad of squads" class="po-lg-4">
                  <div class="po-font-subtitle po-text-center">{{squad.value}}</div>
                  <div class="po-text-center sample-container-dashboard">{{squad.name}}</div>
                </po-widget>
              </po-container>
            </po-accordion-item>
          </po-accordion>
          <br>
        </div>
    
        <po-table id="query_table"
          [p-actions]="actions"
          [p-items]="rowsViewQueries"
          [p-columns]="columnsQueries"
          [p-hide-detail]="true"
          [p-loading]="loading"
          [p-literals]="customLiterals"
          p-sort="true"
          p-striped="true"
          p-container="shadow"
          p-actions-right="true"
          (p-show-more)=showMore()>
        </po-table>
      </po-tab>  
    </po-tabs>  
    <po-divider></po-divider>
  </div>
</div>
</po-page-dynamic-search>

<po-modal 
      p-click-out="false" 
      [p-primary-action]="confirm" 
      p-size="auto" 
      p-title="Detalhes da Query @ {{detail?.file_name}}"
      >
      
      <po-info class="po-sm-6" p-label="File" [p-value]="detail?.source"> </po-info>
      <po-info class="po-sm-3" p-label="Source Date" [p-value]="detail?._date | date: 'dd/MM/yyyy HH:mm:ss'"> </po-info>
      <po-info class="po-sm-3" p-label="Criticidade" [p-value]="detail?.criticidade"> </po-info>      

      <po-divider></po-divider>

      <po-info class="po-sm-3" p-label="Funçâo" [p-value]="detail?.function"> </po-info>
      <po-info class="po-sm-2" p-label="Linha" [p-value]="detail?.line"> </po-info>      
      <po-info class="po-sm-4" p-label="Squad" [p-value]="detail?.squad"> </po-info>
      <po-info class="po-sm-3" p-label="Segmento" [p-value]="detail?.segmento"> </po-info>

      <po-divider></po-divider>

      <po-info class="po-sm-2" p-label="Módulo" [p-value]="detail?.modulo"></po-info>
      <po-info class="po-sm-3" p-label="Programa" [p-value]="detail?.programa"></po-info>
      <po-info class="po-sm-2" p-label="Time Elapsed" [p-value]="detail?.time_elapsed"> </po-info>      
      <po-info class="po-sm-3" p-label="Data de execução" [p-value]="detail?._timestamp | date: 'dd/MM/yyyy'"> </po-info>

      <po-divider></po-divider>

    <div class="po-row">
      <po-button 
        class="po-sm-md-lg-1" 
        p-label="" 
        p-icon="po-icon-copy" 
        (p-click)="copyToClipboard()" 
        p-type=default
        p-small="true">
      </po-button>
      <div 
        class="po-sm-md-lg-11 highlights" 
        [innerHTML]="highlightedText">
      </div>
    </div>

    <div class="div-card po-sm-12 po-md-12 po-lg-12" *ngIf="this.detail?.issueCode">
      <br>
      <po-divider p-label="Issue details" class="po-sm-12 po-md-12 po-lg-12"></po-divider>
      <div class="po-sm-4 po-md-4 po-lg-4">
        <p>Apoio: <a [href]="detail?.issueUrl" target="_blank">{{ this.detail?.issueCode }}</a> </p>
      </div>
      <div class="po-sm-4 po-md-4 po-lg-4">
        <p>Status: {{ this.detail?.issueStatus }} </p><br>
      </div>
    </div>
    <br>
    <div class="po-sm-md-lg-4 apoio_button" *ngIf="!this.detail?.issueCode">
      <po-button (p-click)="showAIModel()" p-danger="true" p-kind="secondary"  p-icon="po-icon-warning" p-label="Abrir Apoio" [p-loading]="btnCreateLoading">
      </po-button>
    </div>
    <br>
    <po-divider></po-divider>

    <div class="po-row ">
      <div class="po-sm-md-lg-3 tqa-planning">
        <po-select class="po-sm-md-lg-3"
          name="planning"
          [(ngModel)]="planning"
          p-label="Planejado para:"
          [p-options]="[{label:'Não Planejado',value:''},{label:'P1212310',value:'P1212310'},{label:'P1212410',value:'P1212410'}]"
          [p-readonly]=disalbledPlanning()
          [p-help]="helpPlanning()"
          >
        </po-select>
        <br>
        <div class="po-sm-md-lg-3" id=tqa-button-div > 
          <po-button 
            class="round_button"
            (p-click)="btnFalseReport()" 
            p-danger="false" 
            p-kind="tertiary"  
            p-icon="po-icon-minus-circle" 
            p-label="Falso Positivo" 
            [p-loading]="btnCreateLoading">
          </po-button>
        </div>
      </div>
      <div class="obs_div">
        <po-textarea   
          [(ngModel)]="obsquery"
          [p-optional]="true" 
          p-label="Observação | Justificativa"
          p-placeholder="Se necessário, insira uma justificativa."
          p-rows="7"
          [p-readonly]="isReadOnly"
        ></po-textarea>
      </div>
    </div>
    
    <br>
    
    <po-button 
      class="po-sm-12" 
      p-label="Atualizar" 
      (p-click)="updateQuery()" 
      p-kind="primary"
      [p-disabled]="!isReadOnly"
    ></po-button>
    
    </po-modal>

