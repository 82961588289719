export class Machine {
    public id: string;
    public title: string;
    public ip: string;
    public softwareType: string;
    public total: number;
    public created: number;
    public series: any[];
    public usage: string;
    public category: string[];

}