import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceHM {
  private toastSubject = new Subject<any>();

  getToastObservable() {
    return this.toastSubject.asObservable();
  }

  showToast(text: string, type: string) {
    this.toastSubject.next({text, type});
  }
}